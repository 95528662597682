.requests {
  padding: 0 .5vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* -----Others----- */

.searchFieldSolicitudesAdminContainer {
  width: 35vw !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  margin: 0 2vw 0 2vw !important;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 6px 25px #00000029;
  border-radius: 10px !important;
  opacity: 1;
}

.searchFieldSolicitudesAdmin,
.searchFieldSolicitudesAdmin:focus {
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}

.searchButtonSolicitudesAdmin,
.searchButtonSolicitudesAdmin:hover,
.searchButtonSolicitudesAdmin:focus {
  background: var(--white) !important;
  box-shadow: none !important;
  border: none !important;
  box-shadow: none !important;
}

.iconLupa {
  width: 1vw !important;
}

::-webkit-input-placeholder {
  font-size: 0.9vw !important;
  color: var(--dark-grey) !important;
  font-family: var(--poppins-light);
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 0.9vw !important;
  color: var(--dark-grey) !important;
  font-family: var(--poppins-light);
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 1.5vw !important;
  color: var(--dark-grey) !important;
  font-family: var(--poppins-light);
}

.TestCargaMasivaContainer {
  display: flex;
  justify-content: center;
  padding: 5vh 0;
}

.TestCargaMasivaBtn {
  padding: 1vh 2vw;
  border-radius: 10px;
  background: var(--orange);
  color: var(--white);
  border: 1px solid var(--white);
  font-family: var(--poppins-medium);
  font-size: 1.3rem;
}