.pqrsListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5vh;
}

.pqrsList {
  width: 98%;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 1vh;
}

.pqrsListHead {
  width: 100%;
}

.pqrsListHeadRow {
  background-color: var(--dark-purple);
  color: var(--white);
  font-family: var(--poppins-light);
  font-size: 1vw;
}

.pqrsListHeadCell {
  padding: 0 1vw;
  height: 7.5vh;
  font-size: 2vh;
  text-align: center;
}

.flexCenter{
  justify-content: center !important;
}

.textCenter {
  text-align: center !important;
}

.pqrsListHeadCell:nth-child(1) {
  width: 5vw;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.pqrsListHeadCell:nth-child(6) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}

.pqrsListTheadDropdowBtnn {
  display: flex;
  justify-content: space-between;
  max-width: 10vw;
}

.pqrsBodyRowCell {
  text-align: left;
}

/* ---Others--- */

.hrPQRSAdmin {
  color: var(--light-purple) !important;
  background: var(--light-purple);
  height: 2px !important;
  opacity: 0.5 !important;
  margin: 0.1rem 0 !important;
  margin-bottom: 3vh !important;
}

.PQRS-table {
  width: 98%;
  max-height: 50vh;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 1vh;
}

.PQRS-table-head {
  background-color: var(--dark-purple);
  color: var(--white);
  font-family: var(--poppins-light);
  font-size: 1vw;
}

.PQRS-table-head th {
  padding: 2vh 2vw;
  position: relative;
}

.PQRS-table-head th:nth-child(1) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.PQRS-table-head th:nth-child(6) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}

.PQRS-table-content-box {
  overflow-y: auto;
}

.PQRS-Table-row {
  background-color: var(--light-grey);
  font-family: var(--poppins-regular);
  font-size: 1vw;
}

.PQRS-Table-row td {
  padding: 1vh 2vw;
}

.PQRS-Table-row td:nth-child(1) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.PQRS-Table-row td:nth-child(6) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}

.userTypeIcon{
  width: 10vw;
}

.dropdown-button {
  border: 0;
  padding-bottom: -2vh;
  width: 1.2vw;
  height: 1.2vw;
  border: 1px solid var(--orange);
  border-radius: 5px;
  display: inline-flex;
}

.dropdown-icon {
  width: 100%;
  height: 100%;
}

.dropdown-body {
  display: none;
  position: absolute;
  background-color: var(--white);
  color: var(--black);
  border: none;
  border-radius: 7px;
  right: 5%;

}

.dropdown-item {
  margin: 1.5vh 1vw;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: var(--orange);
  color: var(--white);
}

.dropdown-active {
  display: block;
}

.stateIcon {
  width: 2vw;
}

.PQRS-editButton {
  width: 2vw;
}