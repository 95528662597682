.pqrsBodyRow {
    background-color: var(--light-grey);
}

.pqrsBodyRowCell {
    padding: 0 1vw;
    height: 7.5vh;
    font-size: 2vh;
    font-family: var(--poppins-light);
    font-size: 2vh;
    color: var(--dark-grey4);
    text-align: start;
}

.pqrsBodyRowCell:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.pqrsBodyRowCell:nth-child(6) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
}

.pqrsBodyRowEditButton {
    width: 2vw;
}