.NoDataInfo{
    height: 55vh;
    padding: 2vh 4vw;
}

.NoDataInfoTitle{
    font-size: 1.7vw;
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
}

.NoDataInfoSubtitle{
    font-size: 1.3vw;
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
}

.orangeText{
    color: var(--orange);
}