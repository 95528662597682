@import '/src/App.css';

.unregisteredUsersHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 24vW;
}

.unregisteredUsersHeaderParagraph {
  color: var(--light-purple);
  font-size: 1.5vw;
  line-height: 4rem;
  font-family: var(--poppins-bold) !important;
  border-bottom: 5px solid var(--light-purple);
  margin-bottom: -4px;
}

.hrUnregisteredUsers {
  color: var(--light-purple) !important;
  background: var(--light-purple);
  height: 2px !important;
  opacity: .50 !important;
  /* margin: 0.1rem 0 !important; */
  margin: 0.1rem 0 6rem 0 !important
}

.unregisteredUsersGeneralContainer {
  flex: 1;
  padding: 0 2vw;
  padding-bottom: 2vh;
  height: 72%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.unregisteredUsersGeneralSubcontainer {  
  display: flex;
  flex-direction: row;
  gap: 1.5vw;
}

.unregisteredUsersSubcontainer1 {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--light-grey);
  padding: 2vw 2vw 0;
}

.unregisteredUsersSubcontainer1 div{
  margin-bottom: 2vh;
}

.unregisteredUsersSubcontainer2{
  margin-top: 3vh;
}

.unregisteredUsersSubcontainer3{
  height: 35%;
}

.unregisteredUsersTitle{
  color: var(--light-purple);
  font-family: var(--poppins-bold);
  font-size: 1.5vw;
  margin-bottom: 2vh;
}

.unregisteredUsersTextArea1 {
  width: 50vw;
  height: 85%;
  max-height: 28vh;
  resize: none;
  background: var(--light-grey);
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 7px;
  border: none;
  font-family: var(--poppins-light);
  font-size: 1.5vw;
  color: var(--dark-grey4);
  padding: 1vh 1vw;
  white-space: pre-line;
  word-wrap: break-word;
  overflow-y: auto;
}

.unregisteredUsersTextArea2 {
  width: -webkit-fill-available;
  resize: none;
  background: var(--light-grey);
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 7px;
  border: none;
  font-family: var(--poppins-light);
  font-size: 1.5vw;
  color: var(--dark-grey4);
  padding: 1vh 1vw;
}

.unregisteredUsersFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.unregisteredUserH3 {
  font-size: 1.5vw;
  font-family: var(--poppins-medium);
  color: var(--dark-grey4);
  margin-bottom: 0;
}

.unregisteredUserH3Note {
  font-size: 1.05vw;
  margin-left: 2vw;
}

.unregisteredUsersBottomTitle{
  font-size: 1.5vw;
  font-family: var(--poppins-medium);
  color: var(--dark-grey4);
  margin-bottom: 1vh;
}

.unregisteredUserP {
  font-family: var(--poppins-light);
  font-size: 1.5vw;
  color: var(--dark-grey4);
}

.unregisteredUserButton,
.unregisteredUserButton:disabled {
  background: var(--orange);
  color: var(--white);
  border: none;
  border-radius: 10px;
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
  padding: 1vh 1.5vw;
}

.unregisteredUserButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.unregisteredUsersFooterBackLink,
.unregisteredUsersFooterBackLink:hover {
  text-decoration: none;
  color: var(--light-purple);
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
}

.unregisteredUsersInputRadioLabel {
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
  color: var(--dark-grey4);
}

.unregisteredUsersInputRadio {
  width: 1.5vw;
  height: 1.5vw;
}

.unregisteredUsersInputsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2vw;
}

.inputRadioContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5vw;
}