#RequestDetail {
  width: 70%;
  margin: 0 auto;
  padding-top: 5vh;
}

.RequestDetailTitle {
  color: var(--light-purple);
  font-family: var(--poppins-bold);
  font-size: 1.7vw;
}

.RequestDetailTopBox {
  background-color: var(--light-grey);
  margin-bottom: 2vh;
  display: flex;
  gap: 5vw;
  padding: 1vw;
  height: 10vh;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 1.3vw;
}

.RequestDetailTopBoxCompany {
  font-family: var(--poppins-regular);
}

.RequestDetailTopBoxDate {
  font-family: var(--poppins-medium);
  color: var(--dark-grey4);
}

.RequestDetailBottomBox {
  background-color: var(--light-grey);
  margin-bottom: 2vh;
  gap: 5vw;
  padding: 1vw;
  min-height: 10vh;
  color: var(--dark-grey4);
  box-shadow: 0px 3px 6px #00000029;
  font-size: 1.3vw;
}

.RequestDetailBottomBoxTitles {
  font-family: var(--poppins-medium);
}

.RequestDetailBottomBoxText {
  font-family: var(--poppins-regular);
}

.RequestDetailBottomBoxReadmore {
  font-family: var(--poppins-bold);
}

.RequestDetailBtnBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1vw;
  font-size: 1.3vw;
  margin-top: 5vh;
}

.RequestDetailBtn,
.RequestDetailBtnDisabled {
  background-color: var(--orange);
  border: 0;
  color: var(--white);
  font-family: var(--poppins-medium);
  padding: 1vh 2vw;
  border-radius: 10px;
}

.RequestDetailBtnDisabled,
.RequestDetailBtnDisabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

.RequestDetailBodyContent {
  max-height: 40vh;
  overflow-y: auto;
}

.RequestDetailTitleContainer,
.RequestDetailReadMsgContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.RequestDetailTitleContainer {
  margin-bottom: 2vh;
}

.RequestDetailReadMsgText{
  font-family: var(--poppins-regular);
  color: var(--dark-grey4);
  font-size: 1vw;
}

.RequestDetailReadMsgContainer {
  gap: 1vw;
}

.RequestDetailReadMsgCheckboxContainer {
  width: 1.4vw;
  height: 1.4vw;
  border: solid 1px #636363;
  border-radius: 0.3vw;
  overflow: hidden;
}
.RequestDetailReadMsgCheckbox {
  width: 100%;
  height: 100%;
  border: none;
}