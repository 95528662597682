#DocumentsUpdate {
    padding: 2vh 4vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.DocumentsUpdateFirstTitle {
    font-family: var(--poppins-semibold);
    font-size: 1.5vw;
    color: #000;
    width: 62%;
    line-height: 3vh;
    margin-bottom: 3vh;
}

.DocumentsUpdateFirstText {
    font-family: var(--poppins-regular);
    font-size: 1.5vw;
    color: var(--dark-grey3);
    margin-bottom: 3vh;
}

.DocumentsUpdateSelectTypeDoc {
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    color: var(--dark-grey3)
}

.inputField {
    position: relative;
    width: 40%;
}

.DocumentsUpdateInput {
    width: 100%;
    height: 5vh;
    padding: .9vh .5vw;
    border-radius: 5px;
    outline: none;
    border: 1px solid var(--dark-grey3);
    background-color: transparent;
}

.DocumentsUpdateButtonsBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: .5vw;
}

.DocumentsUpdateButtonsBox input {
    display: none;
}

.DocumentsUpdateButtonsBox label {
    cursor: pointer;
    text-overflow: ellipsis;
}

.DocumentsUpdateButtonsBox label:disabled {
    cursor: none;
}

.DocumentsUpdateDropdownOption {
    margin: .5vh 0;
    padding: .5vh 1vw;
    cursor: pointer;
    color: var(--dark-grey3);
    font-family: var(--poppins-light);
    font-size: 1vw;
}

.DocumentsUpdateDropdownOption:hover {
    background-color: var(--orange);
    color: var(--white);
}

.DocumentsUpdateButtonsBoxBtn {
    color: var(--white);
    background-color: var(--orange);
    width: 17vw;
    height: 5.5vh;
    font-family: var(--poppins-medium);
    font-size: 1.4vw;
    border: 0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DocumentsUpdateButtonsBoxBtn:disabled {
    background-color: var(--white);
    color: var(--dark-grey3);
    border: 1px solid var(--dark-grey);
}

.DocumentsUpdateButtonsBoxBtnDisabled {
    background-color: var(--white);
    color: var(--dark-grey3);
    border: 1px solid var(--dark-grey);
    width: 17vw;
    height: 5.5vh;
    font-family: var(--poppins-medium);
    font-size: 1.4vw;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.documentsUpdateFileContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5vw;
    font-family: var(--poppins-regular);
    color: var(--dark-grey3);
}

.documentsUpdateFileContainer p{
    white-space: nowrap;
    overflow: hidden;
    width: 15vw;
    font-size: 1.3vw;
    text-overflow: ellipsis;
}

.documentsUpdateFileLogo {
    width: 1.5vw;
}

.documentsUpdateButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1vh;
}