#PagosTuDataControl {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 5vh;
}

#PagosTuDataControl table {
    width: 95%;
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0 1vh;
}

#PagosTuDataControl thead {
    background-color: var(--dark-purple);
    font-family: var(--poppins-light);
    color: var(--white);
}

#PagosTuDataControl th,
#PagosTuDataControl tbody td {
    padding: 0 1vw;
    height: 7.5vh;
    font-size: 2vh;
}

#PagosTuDataControl tbody td {
    font-family: var(--poppins-regular);
    font-size: 2vh;
    color: var(--dark-grey4);
    text-align: start;
}

#PagosTuDataControl th:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#PagosTuDataControl th:nth-child(5) {
    text-align: center;
}

#PagosTuDataControl th:nth-child(6) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
}

#PagosTuDataControl tbody tr {
    background-color: var(--light-grey);
    font-family: var(--poppins-regular);
    padding: 0 2vw;
}

#PagosTuDataControl td:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#PagosTuDataControl td:nth-child(5) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
}

#PagosTuDataControl td:nth-child(6) {
    text-align: center;
}

#PagosTuDataControl img {
    width: 2vw;
}

.PagosTuDataControlDetailsBtn {
    border: none;
}