@import '/src/App.css';

.mainTable {
  width: 98%;
  border-collapse: separate;
  border-spacing: 0 2vh;
  text-align: left;
}

.trHeaderGeneralBA {
  background-color: var(--dark-purple);
  color: var(--white);
  border-bottom: 1px solid var(--purple-transparent_overlay);
  font-family: var(--poppins-light);
  font-size: 1vw;
}

.generalTableRow {
  background-color: var(--light-grey);
  border-radius: 20px;
}

.thIdBA,
.thTipoSolicitudBA,
.thEmpresaBA,
.thNitBA,
.thSolicitanteBA,
.thEstadoBA {
  padding: 0 1vw;
  height: 7.5vh;
  font-family: var(--poppins-regular);
  font-size: 2vh;
  color: var(--dark-grey4);
  text-align: start;
}

.mainTable th {
  padding: 0 1vw;
  height: 7.5vh;
  font-family: var(--poppins-regular);
  font-size: 2vh;
  color: var(--white);
  text-align: start;
}

.thSolicitanteBA,
.thTipoSolicitudBA {
  max-width: 13vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.thEmpresaBA2 {
  text-align: center;
  padding: 0 1rem;
  font-family: var(--poppins-regular);
  color: var(--dark-grey4);
  font-size: 2vh;
}

.thIdBA {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 1vw;
  height: 7.5vh;
  font-family: var(--poppins-regular);
  font-size: 2vh;
  color: var(--dark-grey4);
  text-align: start;
}

.thAccionesBA {
  padding: 1.5vh 1rem 0 1rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.thNitBA {
  width: 16vw;
}

.estadoOvalo {
  border-radius: 10px;
  background: #ffb300;
  text-align: center;
  color: var(--white);
  padding: 0.3vh;
}

.editButton {
  display: flex;
}

.actionButton {
  width: 2vw;
  cursor: pointer;
  text-decoration: none;
}

.actionButtonHidden {
  display: none;
}

.table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-height: 70vh;
  overflow: auto;
  padding-bottom: 5vh;
}

.table-db-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  padding-bottom: 5vh;
}

.tableHeaderTG {
  position: sticky;
  top: 0;
}

.detailsButton {
  width: 8vw;
  text-decoration: none;
  border: 1px solid var(--dark-grey4);
  border-radius: 5px;
  padding: 0.5vh 2vw;
  color: var(--dark-grey3);
  background: var(--white);
  font-size: 2vh;
  font-family: var(--poppins-light);
}

.detailsButton:hover {
  background: var(--orange);
  color: var(--white);
}

.requestTableRequestSelect2 {
  width: 1.5vw;
  height: auto;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../../assets/icons/icon_flecha_naranja.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70%;
  margin-left: 1vw;
  border: 0;
  border-radius: 3px;
}

table thead th {
  padding: 0 1vw;
  height: 7.5vh;
  font-size: 2vh;
}