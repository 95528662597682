.notAllowedContactRowLawyers {
    padding: 0 3vw;
    display: grid;
    grid-template-columns: 15% 25% 20% 20% 20%;
    background-color: var(--light-grey);
    border-radius: 0.5vw;
    margin: 0.6vw 0;
}

.notAllowedContactRowCell {
    display: flex;
    align-items: center;
    padding: 0 1vw;
    height: 7.5vh;
    font-size: 2vh;
    font-family: var(--poppins-regular);
    color: var(--dark-grey4);
}

.notAllowedContactRowCell:nth-child(4){
    justify-content: center;
}

.notAllowedContactFourthRowCell {
    padding-left: 1vw;
}

.notAllowedContactFifthRowCell {
    display: flex;
    justify-content: center;
}

.notAllowedContactFifthRowCell img {
    width: 2vw;
}