.navFooter {
  padding: 0 2vw;
  position: relative;
  bottom: 0;
  transform: translate(0, 50%);
}

.navFooter,
.navFooterBackLink,
.navFooterButtonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}

.navFooterBackButton{
  border: 0;
  background-color: var(--white);
}

.navFooterBackLinkImage {
  width: 2.0vw;
}

.navFooterBackLinkText,
.navFooterBackLinkText:hover {
  color: var(--light-purple);
  font-size: 1.3vw;
  font-family: var(--poppins-medium);
}

.navFooterButtonsContainer {
  gap: 2.5vw;
}

.navFooterButton,
.navFooterButton2Disabled,
.navFooterButton1AlternativeDisabled {
  width: 16vw;
  border: none;
  font-size: 1.3vw;
  font-family: var(--poppins-medium);
  border-radius: 0.8vw;
  padding: 0.5vw 1.5vw;
  text-decoration: none;
  text-align: center;
}

.navFooterButton1,
.navFooterButton1:hover {
  background-color: var(--white);
  border: solid 1px var(--dark-grey3);
  color: var(--dark-grey2);
}

.navFooterButton1Alternative,
.navFooterButton1AlternativeDisabled {
    background-color: var(--white);
    border: solid 1px var(--dark-grey3);
    color: var(--dark-grey2);
}

.navFooterButton1AlternativeDisabled,
.navFooterButton1AlternativeDisabled:hover {
  cursor: not-allowed;
}

.navFooterButton1Alternative:hover {
    background-color: var(--orange);
    border: solid 1px var(--orange);
    color: var(--white);
}

.navFooterButton2,
.navFooterButton2:hover,
.navFooterButton2Disabled {
  background-color: var(--orange);
  color: var(--white);
}

.navFooterButton2Disabled,
.navFooterButton2Disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}