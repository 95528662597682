#PagoEntidades {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 5vh;
}

#PagoEntidades table {
    width: 95%;
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0 1vh;
}

#PagoEntidades thead {
    background-color: var(--dark-purple);
    font-family: var(--poppins-light);
    color: var(--white);
}

#PagoEntidades th,
#PagoEntidades tbody td {
    padding: 0 1vw;
    height: 7.5vh;
    font-size: 2vh;
}

#PagoEntidades tbody td {
    font-family: var(--poppins-regular);
    font-size: 2vh;
    color: var(--dark-grey4);
    text-align: start;
}

#PagoEntidades th:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#PagoEntidades th:nth-child(6) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#PagoEntidades tbody tr {
    background-color: var(--light-grey);
    font-family: var(--poppins-regular);
    padding: 0 2vw;
}

#PagoEntidades td:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#PagoEntidades td:nth-child(6) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#PagoEntidades img {
    width: 2vw;
}

.PagoEntidadesDetailsButton {
    border: 1px solid var(--dark-grey4);
    border-radius: 12px;
    font-family: var(--poppins-light);
    padding: 0.5vh 1vw;
    background: var(--white);
}