@font-face {
  font-family: "Poppins-Light";
  src: url(./assets/fonts/poppins/Poppins-Light.otf);
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(./assets/fonts/poppins/Poppins-Regular.otf);
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url(./assets/fonts/poppins/Poppins-Medium.otf);
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(./assets/fonts/poppins/Poppins-Bold.otf);
  font-style: normal;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(./assets/fonts/poppins/Poppins-SemiBold.otf);
  font-style: normal;
}

@font-face {
  font-family: "Quicksand-Bold";
  src: url(./assets/fonts/quicksand/Quicksand_Bold.otf);
  font-style: bold;
}

@font-face {
  font-family: "Quicksand-Light";
  src: url(./assets/fonts/quicksand/Quicksand_Light.otf);
  font-style: light;
}

:root {
  --quicksand-bold: "Quicksand-Bold";
  --quicksand-light: "Quicksand-Light";
  --poppins-light: "Poppins-Light";
  --poppins-regular: "Poppins-Regular";
  --poppins-medium: "Poppins-Medium";
  --poppins-semibold: "Poppins-SemiBold";
  --poppins-bold: "Poppins-Bold";
  --button-size: 3vh;
  --paragraph-font-size: 1em;
  --paragraph-font-size-2: 0.75em;
  --light-purple: #A10268;
  --dark-purple: #73154F;
  --dark-aside-purple: #440a2f;
  --purple-transparent: rgba(115, 21, 79, 0.2);
  --purple-transparent_overlay: rgba(115, 21, 79, 0.45);
  --white-transparent_overlay: rgba(255, 255, 255, 0.5);
  --overlay-boxshadow: rgba(31, 38, 135, 0.37);
  --orange: #FF5D00;
  --inactive-orange: #ffae7f;
  --orange-transparent: rgba(210, 76, 0, 0.7);
  --light-grey: #F0F0F0;
  --light-grey2: #F2F2F2;
  --dark-grey: #9E9E9E;
  --dark-grey2: #8A8A8A;
  --dark-grey3: #707070;
  --dark-grey4: #595959;
  --dark-grey5: #EBEBEB;
  --dark-grey__transparent: rgba(158, 158, 158, 0.5);
  --black-transparent: rgba(255, 255, 255, 0.18);
  --green: #45AC4D;
  --green2: #34833C;
  --white: #FFFFFF;
  --white2: #FFF4E9;
  --black: #3A0B28;
  --black2: #252525;
  --red: #FF0A0A;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

.importantText {
  color: var(--orange);
}

.table>:not(caption)>*>* {
  border-bottom: none;
}

.form-check-input[type=radio] {
  border-radius: 5px;
  width: 2vw;
  height: 2vw;
}

.form-check-input:checked {
  background-color: var(--dark-grey2);
  border-color: var(--dark-grey2);
}

.form-check-label {
  padding-left: 2vh;
  color: var(--dark-grey);
  font-family: var(--poppins-light);
  font-size: 1.5vw;
  margin-top: 0vh;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

/* Ocultar el ojo en inputs de tipo password */
input[type="password"]::-ms-reveal,
input[type="password"]::-webkit-password-reveal {
  display: none !important;
}