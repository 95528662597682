#UsersLayout {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.UsersLayoutHeader {
    margin: 0 2vw;
    display: flex;
    justify-content: space-between;
}

.UsersLayoutHeader div {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.usersPanelHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
}

.usersPanelParagraph {
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    margin-bottom: -4px;
    font-family: var(--poppins-medium) !important;
}

.activeHeader {
    text-decoration: none;
}

.activeHeader p {
    font-family: var(--poppins-bold) !important;
    border-bottom: 5px solid var(--light-purple);
    height: 100%;
    display: flex;
    align-items: center;
}

.hrUsersPanel {
    color: var(--light-purple) !important;
    background: var(--light-purple);
    height: 2px !important;
    opacity: .50 !important;
    margin: 0.1rem 0 !important;
}

.guideButton {
    background-image: url(../../../assets/icons/icon_touarpp-02.svg);
    background-color: transparent;
    border: 0;
    width: 4.3vw;
    height: 4.3vw;
    background-repeat: no-repeat;
    background-size: cover;
}

.guideButton:hover {
    background-image: url(../../../assets/icons/icon_touarpp-01.svg);
}