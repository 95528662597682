@import '/src/App.css';

#createNewUserFormContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.createNewUserFormSubContainerR {
  margin-left: 2vw;
}

.createNewUserFormSubContainerR,
.createNewUserFormSubContainerL {
  width: 49%;
}

.createNewUserMainContent,
.createNewUserFormSubContainerR,
.createNewUserFormSubContainerL {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1vh;
}

@media (min-height: 600px) {

  .createNewUserMainContent,
  .createNewUserFormSubContainerR,
  .createNewUserFormSubContainerL {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: .5vh;
  }
}

.formSubContainerLNombreApellidoContainer,
.formSubContainerLPaisCiudadContainer,
.formSubContainerRGeneralContainerA,
.formSubContainerRGeneralContainerB,
.formSubContainerRGeneralContainerC {
  display: flex;
  flex-direction: row;
}

.createNewUserInputBox {
  font-family: var(--poppins-light);
  width: -webkit-fill-available;
}

.textboxIconPasswordContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 37vw;
}

.passwordOpenEyeImg {
  width: 2.5vw;
  height: fit-content;
  position: relative;
  right: 4.5vw;
  cursor: pointer;
  margin: auto;
}

.createUserPasswordTitle {
  font-family: var(--poppins-regular);
  color: var(--dark-grey4);
  font-size: 1vw;
}

.createUserPasswordSubtitle,
.createNewUserInputTitle {
  width: 120%;
  display: block;
  margin: 1vh 0;
  font-family: var(--poppins-medium);
  color: var(--dark-grey4);
  font-size: 1vw;
}

.createNewUserInputTitle {
  margin: 0;
}

.redAdvice {
  color: var(--red);
  font-family: var(--poppins-light);
  font-size: 1.1vw;
  margin-top: -0.5vw;
}

.createNewUserInput,
.createNewUserNameInputIncorrectFormat,
.createNewUserInputIncorrectFormat {
  box-shadow: inset 6px 8px 25px #00000029;
  border: 0;
  width: -webkit-fill-available;
  height: 7vh;
  padding: 1.7vh 1vw;
  border-radius: 10px;
  font-size: 1vw;
  margin-right: 1vw;
}

.createNewUserInputIncorrectFormat {
  border: solid 0.1vw red;
}

.createNewUserNameInputIncorrectFormat {
  border: solid 0.1vw red;
}

.createNewUserInputDate,
.createNewUserInputDateIncorrectFormat {
  box-shadow: inset 6px 8px 25px #00000029;
  border: 0;
  width: -webkit-fill-available;
  height: 7vh;
  padding: 1.7vh 1vw;
  border-radius: 10px;
  font-size: 1.7vh;
  margin-right: 1vw;
  color: var(--dark-grey);
}

.createNewUserInputDateIncorrectFormat {
  border: solid 0.1vw red;
}

.createNewUserSelect,
.createNewUserSelectIncorrectFormat {
  box-shadow: inset 6px 8px 25px rgba(0, 0, 0, 0.5);
  opacity: 0.3;
  border: none;
  width: -webkit-fill-available;
  height: 7vh;
  padding: 1.7vh 1vw;
  border-radius: 10px;
  font-size: 1.7vh;
  margin-right: 1vw;
  cursor: pointer;
}

.createNewUserSelect:disabled{
  opacity: .4;
}

.createNewUserSelectIncorrectFormat {
  border: solid 0.25vw red !important;
}

.createNewUserFormContainer {
  padding: 0 3vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 77vh;
  justify-content: space-between;
  padding-bottom: 1vw;
}

.createNewUserFormHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  justify-content: center;
  padding: 1vh 1vw;
  border-radius: 15px;
}

.IdSelect {
  width: 40% !important;
}

.BirthdateSelect,
.GenderInput {
  width: 60% !important;
}

.adminColor {
  border: 3px solid var(--orange);
}

.lawyerColor {
  border: 3px solid var(--dark-purple);
}

.operativeColor {
  border: 3px solid var(--light-purple);
}

.createNewUserFormHeaderContainerImg {
  width: 3vw;
  margin-right: 1vw;
}

.createNewUserFormHeaderContainerParagraph {
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
  color: var(--dark-grey3);
}


.createNewUserHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 24vw;
  padding: 0 3vw;
}

.createNewUserHeaderContainerParagraph {
  color: var(--light-purple);
  font-size: 1.5vw;
  line-height: 4rem;
  font-family: var(--poppins-bold) !important;
  border-bottom: 5px solid var(--light-purple);
}

.hrCreateNewUser {
  color: var(--light-purple) !important;
  background: var(--light-purple);
  height: 2px !important;
  opacity: .50 !important;
  margin: 0.1rem 0 2rem 0 !important;
  position: relative;
  bottom: 5px;
}

.createNewUserFooter {
  margin-top: 0.3vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createNewUserFooterBackLink,
.createNewUserFooterBackLink:hover {
  text-decoration: none;
  background: none;
  border: 0;
  color: var(--light-purple);
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
}

.createNewUserFooterButton,
.createNewUserFooterButton:hover,
.createNewUserFooterButtonDisabled {
  width: 10vw;
  border: 0;
  border-radius: 15px;
  background-color: var(--orange);
  color: var(--white);
  padding: 1vh 0;
  font-size: 1.5vw;
  font-family: var(--poppins-medium);
  text-decoration: none;
  text-align: center;
  margin-right: 1vw;
}

.createNewUserFooterButtonDisabled,
.createNewUserFooterButtonDisabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

.employeeRightFormat {
  display: none !important;
}

.employeeIncorrectFormat {
  color: red !important;
  font-size: 0.8vw !important;
  font-family: var(--poppins-light) !important;
}

.employeeIncorrectUserName {
  color: red !important;
  font-size: 0.8vw !important;
  font-family: var(--poppins-light) !important;
}

.passwordGenerateImg {
  width: 1.9vw;
  margin-right: 0.5vw;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}

.createPasswordInput,
.createPasswordInputIncorrectFormat {
  display: flex;
  justify-content: space-between;
  align-items: center;
}