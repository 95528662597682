@import '/src/App.css';

.usersAdminPanelHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: 21vW;
    text-decoration: none;
}

.usersAdminPanelParagraph {
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    margin-bottom: -4px;
    font-family: var(--poppins-bold) !important;
    border-bottom: 8px solid var(--light-purple);
    margin-left: 2vw;
}

.hrUsersAdminPanel {
    position: relative;
    color: var(--light-purple) !important;
    background: var(--light-purple);
    height: 2px !important;
    opacity: .50 !important;
    margin: 0.1rem 0 !important;
    top: -5px;
}


.newUserButton{
    width: 17vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: none;
    border: 1px solid var(--orange);
    border-radius: 10px;
    padding: 0.8vh 0;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    background-color: var(--orange);
    color: var(--white);
    text-decoration: none;
}

.newUserButton:hover{
    color: var(--white);
}

.iconNewUserButton {
    width: 2vw;
}
