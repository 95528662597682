.entriesListTheadDropdownBtn {
  display: flex;
  justify-content: flex-start;
  gap: 1.5vw;
}

.dropdown-button_EntriesList {
  border: 1px solid var(--orange);
  border-radius: 3px;
  padding: 0 3px;
  margin-left: 0.4vw;
}

.dropdown-button_Selector {
  border: 1px solid var(--orange);
  border-radius: 5px;
  padding: 0 2px;
  margin-left: 0.4vw;
}

.dropdown-icon_EntriesList {
  width: 1vw !important;
  height: 1vw !important;
}

.dropdown-button_Selector-Left {
  width: 2vw;
  transform: rotate(0deg);
}

.dropdown-button_Selector-Right {
  width: 2vw;
  transform: rotate(180deg);
}

.dropdown-body_EntriesList {
  display: none;
  position: absolute;
  background-color: var(--white);
  color: var(--black);
  border: none;
  border-radius: 7px;
  top: 13vh;
  z-index: 2;
  width: 120%;
  height: 10vh;
  -webkit-box-shadow: inset 0px 4px 15px -8px #000000;
  box-shadow: inset 1px 4px 15px -8px #000000;
}

.identity {
  right: 57%;
}

.user {
  top: 6vh;
}

.dropdown-item_EntriesList {
  margin: 0 0;
  padding: .5vh 0;
  padding-right: 0.5vw;
  border-radius: 5px;
  cursor: pointer;
  color: var(--dark-grey2);
  font-family: var(--poppins-regular);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .2s;
}

.dropdown-item_EntriesList:hover,
.dropdown-item_EntriesList-selected {
  background-color: var(--orange);
  color: var(--white);
}

.dropdown-item_EntriesList p {
  padding: .3vh 1vw;
  font-family: var(--poppins-light);
}

.dropdown-active_EntriesList {
  display: block;
}

.carousel-controls_button {
  border: none;
}

.dropdownIconItem {
  width: 0.8vw;
}

.dropdownWhiteIconItem{
  width: 0.8vw;
  display: none;
}

.dropdown-item_EntriesList:hover .dropdownWhiteIconItem,
.dropdown-item_EntriesList-selected .dropdownWhiteIconItem{
  display: flex;
}

.dropdown-item_EntriesList:hover .dropdownIconItem,
.dropdown-item_EntriesList-selected .dropdownIconItem{
  display: none;
}

.requestGridDropdownInput{
  margin: 1vh .5vw;
  outline: none;
  border: 0;
  border-bottom: 1px solid var(--dark-grey2);
}