#CustomSelect {
    position: relative;
    width: 100%;
}

#CustomSelect option {
    font-family: var(--poppins-light);
    color: var(--dark-grey2);
    margin: 0;
}

.CustomSelectMain {
    width: 100% !important;
    cursor: pointer;
    padding: 1.5vh 1vw 1.5vh 0;
    display: flex;
    box-shadow: inset 6px 8px 25px #00000029;
    border-radius: 8px;
    justify-content: space-between;
}

.CustomSelectMain img {
    width: 2vw;
}

.CustomSelectOptions {
    width: 100% !important;
    position: absolute;
    z-index: 2;
    top: 5.8vh;
    padding: 1.5vh 0;
    box-shadow: inset 6px 8px 25px #00000029;
    background: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    max-height: 30vh;
    overflow-y: auto;
}

.CustomSelectOptionsItem {
    cursor: pointer;
    padding: 0 1vw;
    font-size: 1.2vw;
    margin-bottom: .5vh;
    color: var(--dark-grey2);
    font-family: var(--poppins-light);
}

.CustomSelectRotate {
    transition: transform 500ms;
}

.CustomSelectRotate-active {
    transform: rotate(180deg);
}