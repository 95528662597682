.paginationContainer {
  height: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;
}

.paginationPages {
  max-width: 14.5vw;
  height: 2vw;
  display: grid;
  /* grid-template-columns: repeat(10000, 2vw); */
  gap: 0.5vw;
  overflow-x: auto;
  overflow-y: hidden;
  /* Ocultar la barra de desplazamiento */
  scrollbar-width: none; /* Para Firefox */
  -ms-overflow-style: none; /* Para Internet Explorer y Edge */
}

.paginationPages::-webkit-scrollbar {
  display: none; /* Para navegadores basados en WebKit (Chrome, Safari, etc.) */
}

.paginationItem,
.paginationItemActive {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  background-color: var(--inactive-orange);
  overflow: hidden;
}

.paginationItem:hover {
  cursor: pointer;
}

.paginationItemActive {
  background-color: var(--orange);
}

.paginationPrevBtn,
.paginationNextBtn {
  width: 1.5vw;
  height: 1.5vw;
  border-top: solid 0.35vw var(--orange);
  border-left: solid 0.35vw var(--orange);
}

.paginationPrevBtn:hover,
.paginationNextBtn:hover {
  cursor: pointer;
}

.paginationPrevBtn {
  transform: rotate(315deg);
}

.paginationNextBtn {
  transform: rotate(135deg);
}

.paginationActivePrevBtn,
.paginationActiveNextBtn {
  width: 1.5vw;
  height: 1.5vw;
  border-top: solid 0.35vw var(--orange);
  border-left: solid 0.35vw var(--orange);
  pointer-events: none;
}

.paginationActiveNextBtn {
  transform: rotate(135deg);
}

.paginationActivePrevBtn {
  transform: rotate(315deg);
}