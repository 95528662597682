#BolsilloUsuario {
  flex: 1;
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5vh;
}

#bolsilloUsuarioTable {
  border-collapse: separate;
  border-spacing: 0 1vh;
}

#bolsilloUsuarioTable th,
#bolsilloUsuarioTable td {
  padding: 0 1vw;
  height: 7.5vh;
  font-size: 2vh;
}

#bolsilloUsuarioTableHead {
  width: 98%;
  background-color: var(--dark-purple);
  color: var(--white);
  font-family: var(--poppins-light);
  font-size: 1vw;
}

#bolsilloUsuarioTableBody {
  width: 98%;
  margin: 0 auto;
  font-family: var(--poppins-light);
  color: var(--dark-grey3);
  background-color: var(--light-grey);
  border-radius: 10px;
}

#bolsilloUsuarioTableBody td{
  font-family: var(--poppins-regular);
  font-size: 2vh;
  color: var(--dark-grey4);
  text-align: start;
}

#bolsilloUsuarioTableHead th:nth-child(1),
#bolsilloUsuarioTableBody td:nth-child(1){
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#bolsilloUsuarioTableHead th:nth-child(5)
#bolsilloUsuarioTableBody td:nth-child(5){
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

#bolsilloUsuarioTableHead th:nth-child(5),
#bolsilloUsuarioTableBody td:nth-child(5) {
  text-align: center;
}

#bolsilloUsuarioTableHead th:nth-child(6),
#bolsilloUsuarioTableBody td:nth-child(6) {
  text-align: center;
}

#bolsilloUsuarioTableHead th:nth-child(7),
#bolsilloUsuarioTableBody td:nth-child(7) {
  width: 10%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#bolsilloUsuarioTableBody button {
  border: 0;
  background: transparent;
  width: 2vw;
}

#bolsilloUsuarioTableBody button img{
  width: 100%;
}

.editIcon {
  width: 2vw;
}

.carousel-controls {
  width: 20%;
  margin: 0 auto;
  text-align: center;
}

.bolsilloUsuarioHeadCell {
  padding: 2vh 2vw;
  position: relative;
  text-align: left;
}

.bolsilloUsuarioTheadDropdownBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-button_bolsilloUsuario {
  border: 1px solid var(--orange);
  border-radius: 5px;
  padding: 0 2px;
  margin-left: 0.4vw;
}

.dropdown-icon_bolsilloUsuario {
  width: 1.2vw;
  transform: rotate(270deg);
}

.dropdown-body_bolsilloUsuario {
  display: none;
  position: fixed;
  background-color: var(--white);
  color: var(--black);
  border: none;
  border-radius: 7px;
  top: 10.3vw;
  z-index: 2;
  width: 12%;
  height: 10%;
  -webkit-box-shadow: inset 0px 4px 15px -8px #000000;
  box-shadow: inset 0px 4px 15px -8px #000000;
}

.identity {
  right: 54.5%;
}

.name {
  right: 38.4%;
}

.mail {
  right: 20.7%;
}

.dropdown-item_bolsilloUsuario {
  margin: 1.5vh 1vw;
  cursor: pointer;
  color: var(--dark-grey3);
}

.dropdown-item_bolsilloUsuario:hover {
  background-color: var(--orange);
  color: var(--white);
}

.dropdown-active_bolsilloUsuario {
  display: block;
}