@import '/src/App.css';

.iconNewUserButton {
  width: 2vw;
}

#companiesAdmin {
  width: 95%;
  margin: 2vh auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.companiesAdminAddNewCompanyContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.companiesAdminAddNewCompanyBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--orange);
  gap: 0.5vw;
  padding: 0.5vh 1vw;
  border-radius: 0.5vw;
  margin-bottom: 1vh;
  border: none;
  text-decoration: none;
}

.companiesAdminAddNewCompanyImg {
  width: 2vw;
}

.companiesAdminAddNewCompanyTxt {
  font-family: var(--poppins-medium);
  color: var(--white);
  font-size: 1.6vw;
}

#companiesAdmin table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1vh;
}

#companiesAdmin thead {
  background-color: var(--dark-purple);
  color: var(--white);
  font-family: var(--poppins-light);
  font-size: 1.1vw;
}

#companiesAdmin tbody {
  font-family: var(--poppins-regular);
  background-color: var(--light-grey);
}

#companiesAdmin table tbody img {
  width: 2.5vw;
}

#companiesAdmin table th {
  position: relative;
}

#companiesAdmin table th,
#companiesAdmin table td {
  padding: 0 1vw;
  height: 7.5vh;
  font-size: 2vh;
}

#companiesAdmin table td {
  font-family: var(--poppins-light);
  font-size: 2vh;
  color: var(--dark-grey4);
  text-align: start;
}

#companiesAdmin table th:nth-child(1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#companiesAdmin table th:nth-child(6) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

#companiesAdmin table th:nth-child(5),
#companiesAdmin table th:nth-child(6),
#companiesAdmin table td:nth-child(5),
#companiesAdmin table td:nth-child(6) {
  text-align: center;
}

.isActive {
  display: flex;
}

.isOptionsActive {
  display: block;
  bottom: -6.5vh;
}

.isDisabled {
  display: none;
}

.companiesAdminDetailBtn {
  border: none;
}

.companiesAdminPagination {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 5vh;
}