.EditPQRSUserForm{
    display: flex;
    gap: 5vw;
    margin-bottom: 4vh;
}

.EditPQRSUserForm{
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
    font-size: 1.3vw;
}

.EditPQRSUserImg{
    width: 15vw;
    margin-bottom: 3vh;
}

.EditPQRSUserForm input{
    font-family: var(--poppins-light);
    color: var(--dark-grey2);
    font-size: 1.2vw;
    height: 7vh;
    width: 100%;
}

.EditPQRSUserLSide,
.EditPQRSUserRSide{
    width: 50%;
}

.EditPQRSUserDoubleInput{
    display: flex;
    width: 100%;
    gap: 1vw;
}

.EditPQRSUserDoubleInput2{
    width: 100%;
    display: grid;
    grid-template-columns: 60% 37%;
    gap: 1vw;
}

.EditPQRSUserDoubleInput div{
    width: 50%;
}

.EditPQRSUserLSideFName,
.EditPQRSUserLSideLName{
    width: 50%;
}

.EditPQRSUserBtn{
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
    background-color: var(--orange);
    color: var(--white);
    width: 10vw;
    height: 7vh;
    font-family: var(--poppins-bold);
    font-size: 1.1vw;
}

.EditPQRSUserBtn:disabled{
    color: var(--dark-grey);
    background-color: var(--white);
    border: 1px solid var(--dark-grey);
    cursor: not-allowed;
}

.EditDocumentUserBtn{
    cursor: pointer;
    border: 1px solid var(--dark-grey);
    border-radius: 10px;
    background-color: var(--orange);
    color: var(--white);
    width: 30vw;
    height: 7vh;
    font-family: var(--poppins-medium);
    font-size: 1.3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.3vw;
}

.EditDocumentUserBtn img{
    width: 1.5vw;
    margin-right: .5vw;
}

#uploadNewDocId{
    display: none;
}