.companiesLayout {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.companiesLayoutHeaderContainer {
  margin: 0 2vw;
  display: flex;
  justify-content: space-between;
}

.companiesLayoutHeaderContainer div {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.companiesLayoutItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}

.companiesLayoutParagraph {
  color: var(--light-purple);
  font-size: 1.5vw;
  line-height: 4rem;
  margin-bottom: -4px;
  font-family: var(--poppins-medium) !important;
}

.activeHeader {
  text-decoration: none;
}

.activeHeader p {
  font-family: var(--poppins-bold) !important;
  border-bottom: 5px solid var(--light-purple);
  height: 100%;
  display: flex;
  align-items: center;
}

.hrUsersPanel {
  color: var(--light-purple) !important;
  background: var(--light-purple);
  height: 2px !important;
  opacity: .50 !important;
}
