.UserUploadsImageBox {
  display: flex;
  justify-content: center;
  margin-top: 4vh;
}

.UserUploadsImageBox img {
  width: 25vw;
}

.progressContainer {
  width: 45vw;
  height: 6vh;
  background-color: var(--white);
  border: 2px solid var(--orange);
  border-radius: 5px;
  margin: 1vh 0;
  position: relative;
  /* border-radius: 7px; */
}

.progressBar,
.progressBarFileCharged {
  height: 100%;
  background-color: var(--orange);
  font-family: var(--poppins-medium);
  border-radius: 2px;
  color: var(--white);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.progressBarFileCharged {
  justify-content: center;
}

.progressBarMessage {
  position: absolute;
  z-index: 2;
  font-size: 1vw;
  left: 1vw;
  color: var(--white);
}

.progressBarMessageFileCharged {
  font-size: 1vw;
  color: var(--white);
}

.bulkUploadSubContainer2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upLoadMessageState {
  width: 45vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.upLoadMessageStatePercentage {
  font-family: var(--poppins-medium);
  color: var(--dark-grey3);
}

.bulkUploadFooterBackLink,
.bulkUploadFooterBackLink:hover {
  text-decoration: none;
  color: var(--light-purple);
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
  vertical-align: middle;
}

.bulkUploadModalIcon {
  width: 2.5vw;
  margin-bottom: 3vh;
}

.hideProgress {
  color: white;
  user-select: none;
}

.showProgress {
  color: black;
}

.hideContainer {
  display: none;
}

.showContainer {
  display: initial;
}

#UserUploads {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2vh;
  padding-left: 1vw;
}