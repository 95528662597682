#CaseTracking{
    padding: 0 .5vw;
}

.CaseTrackingTable{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1vw;
}

.CaseTrackingTableHead{
    background-color: var(--dark-purple);
    color: var(--white);
    font-family: var(--poppins-light);
    font-size: 1vw;
}

.CaseTrackingTableHead tr th:nth-child(1){
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
}

.CaseTrackingTableHead tr th:nth-child(5){
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
}

.CaseTrackingTableBody{
    background-color: var(--light-grey);
    font-family: var(--poppins-regular);
}

.CaseTrackingTableBody td{
    font-size: 1vw;
    color: var(--dark-grey4);
    padding: 0 1vw;
    height: 7.5vh;
}

.CaseTrackingTableBody td:nth-child(1){
    text-align: center;
}

.CaseTrackingTableBody td:nth-child(4){
    position: relative;
}

.expiredAlert{
    position: absolute;
    top: 2.3vh;
    right: 1px;
}

.expiredAlertText{
    margin-right: .5vw;
}

.expiredAlert img{
    width: 1.5vw;
}

.expiredAlertText{
    color: var(--red);
}

.CaseTrackingTableBody td:nth-child(5){
    display: flex;
    justify-content: center;
}

.CaseTrackingTableBody td:nth-child(5) img{
    width: 2vw;
}

.DropdownBtn {
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: .5vw;
    top: 1vh;
}

.DropdownBtnButton {
    align-items: center;
    background: transparent;
    border: 1px solid var(--orange);
    border-radius: 3px;
    display: flex;
    height: 1.5vw;
    justify-content: center;
    margin-left: .4vw;
    width: 1.5vw;
}

.DropdownBtnButtonImg {
    width: 70%;
}

.DropdownOptions {
    background-color: var(--white);
    border: 1px solid var(--dark-grey);
    border-radius: 5px;
    flex-direction: column;
    left: 1px;
    margin-top: .5vw;
    max-height: 50vh;
    overflow-y: auto;
    padding: 1.2vh 0;
    position: absolute;
    top: 4.5vh;
    width: 100%;
    z-index: 2;
}