#CreateEntityContent {
  margin-top: 8vh;
  padding: 0 2vw;
}

#CreateEntityTitle {
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
  margin-bottom: 5vh;
}

#createEntityGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3vh;
}

.createEntityText {
  font-family: var(--poppins-medium);
}

.createEntityInput {
  border: 0;
  box-shadow: inset 6px 8px 25px #00000029;
  width: 100%;
  padding: 1.5vh 1vw;
  border-radius: 10px;
}

/* estilos reutilizados */

.entries-footer {
  margin-top: 2vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.entriesFooterBackLink {
  text-decoration: none;
  color: var(--light-purple);
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
}

.entriesFooterBackLink:hover {
  color: var(--light-purple);
}

.entriesFooterButton {
  width: 10vw;
  border: 0;
  border-radius: 15px;
  background-color: var(--orange);
  color: var(--white);
  padding: 1vh 0;
  font-size: 1.5vw;
  font-family: var(--poppins-medium);
}
