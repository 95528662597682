@import '/src/App.css';

.newUserButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 98%;
    /* margin: 2vh 0; */
    height: 96px;
}

.newUserButton {
    width: 15vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: none;
    border: 1px solid var(--orange);
    border-radius: 15px;
    padding: 0.5vh 0;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    background-color: var(--orange);
    color: var(--white);
    text-decoration: none;
}

.iconNewUserButton {
    width: 2vw;
}

#DeleteRequests {
    width: 95%;
    margin: 2vh auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

#DeleteRequests table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1vh;
}

#DeleteRequests thead {
    background-color: var(--dark-purple);
    color: var(--white);
    font-family: var(--poppins-light);
    font-size: 1.1vw;
}

#DeleteRequests tbody {
    font-family: var(--poppins-regular);
    background-color: var(--light-grey);
}

#DeleteRequests table tbody img {
    width: 2.5vw;
}

#DeleteRequests table th {
    position: relative;
    /* padding: 1.3vh 2vw; */
}

#DeleteRequests table th,
#DeleteRequests table td {
    padding: 0 1vw;
    height: 7.5vh;
    font-size: 2vh;
}

#DeleteRequests table td {
    font-family: var(--poppins-light);
    font-size: 2vh;
    color: var(--dark-grey4);
    text-align: start;
}

#DeleteRequests table th:nth-child(1) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

#DeleteRequests table th:nth-child(6) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

#DeleteRequests table th:nth-child(5),
#DeleteRequests table th:nth-child(6),
#DeleteRequests table td:nth-child(5),
#DeleteRequests table td:nth-child(6) {
    text-align: center;
}

.UsersPanelDropBtn {
    border: 0;
    width: 1.2vw;
    height: 1.2vw;
    margin-left: .5vw;
    display: inline-flex;
    border: 1px solid var(--orange);
    background-color: var(--white);
    border-radius: 2px;
    padding: .2vw;
}

.UsersPanelDropBtn img {
    width: 100%;
    height: 100%;
}

.UsersPanelDropdown {
    background-color: var(--white);
    padding: .8vh .8vw;
    position: absolute;
    z-index: 2;
    bottom: -4vh;
    left: 2.5vw;
    border-radius: .5vw;
    margin-top: .4vw;
}

.isActive {
    display: flex;
}

.isOptionsActive {
    display: block;
    bottom: -6.5vh;
}

.isDisabled {
    display: none;
}

.UsersPanelDropdown input {
    border-width: 0 0 1px 0;
    outline: none;
    padding-bottom: .5vh;
    width: 10vw;
}

.UsersPanelDropdown button {
    width: 1.4vw;
    height: 1.4vw;
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 1vw;
}

.UsersPanelDropdown div {
    color: var(--dark-grey4);
}

.UsersPanelDropdownItem:hover {
    background-color: var(--orange);
}

.UsersPanelDropdownItem:hover p {
    color: var(--white);
}

.UsersPanelDetailBtn {
    border: none;
}

.UsersPanelPagination {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 5vh;
}