#SelectProcessNoContact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 45vh;
}

.SelectProcessNoContactBox {
    background-color: var(--light-grey);
    padding: 2vh 2vw;
    border-radius: 11px;
    box-shadow: 0px 3px 6px #00000029;
}

.SelectProcessNoContactTitle {
    font-family: var(--poppins-bold);
    font-size: 1.56vw;
    color: var(--light-purple);
}

.SelectProcessNoContactCheck {
    display: flex;
    align-items: center;
    gap: 1vw;
    height: 8vh;
    font-family: var(--poppins-regular);
    color: var(--dark-grey4);
}

.SelectProcessNoContactCheckbox {
    width: 2vw;
    height: 2vw;
    color: var(--orange);
    appearance: none;
    background-color: #fff;
    border: 1px solid var(--orange);
    border-radius: 3px;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    background-size: 2vw;
    background-position: center;
}

.SelectProcessNoContactCheckbox:checked {
    background-color: var(--orange);
}

#uploadFileEntityWithoutChannel {
    display: none;
}

.SelectProcessNoContactBtnBox {
    display: flex;
    justify-content: space-between;
}

.SelectProcessNoContactBtnBox div {
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.SelectProcessDownloadAnnexes{
    background-color: var(--orange);
    color: var(--white);
    border: 0;
    display: flex;
    align-items: center;
    height: 3vh;
    padding: 3vh 2vw;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    border-radius: 10px;
}

.SelectProcessDownloadAnnexes img{
    width: 1.3vw;
    margin-right: .5vw;
}

.SelectProcessSendButton {
    background-color: var(--orange);
    border: 0;
    color: #fff;
    font-family: var(--poppins-medium);
    font-size: 1.56vw;
    width: 18vw;
    padding: .7vh 0;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.SelectProcessUploadButtonImg {
    width: 1.3vw;
    margin-left: 1vw;
}

.selectProcessNoContactUploadedFileContainer {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5vw;
    font-family: var(--poppins-regular);
    color: var(--dark-grey3);
}

.selectProcessNoContactUploadedFileImg {
    width: 1.5vw;
}

.selectProcessNoContactUploadedFileContainer p {
    max-width: 15vw;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}