@import '/src/App.css';

.userValidationHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 24vW;
}

.userValidationHeaderParagraph {
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    font-family: var(--poppins-bold) !important;
    border-bottom: 5px solid var(--light-purple);
    margin-bottom: -4px;
}

.hruserValidation {
    color: var(--light-purple) !important;
    background: var(--light-purple);
    height:2px !important;
    opacity: .50 !important;
    /* margin: 0.1rem 0 !important; */
    margin: 0.1rem 0 6rem 0 !important
}

.userValidationMainContainer {
    margin: 0 2vw;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.userValidationSubContainer1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}

.userValidationInfoContainer {
    width: 30vw;
    min-height: 36vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.userValidationInfoSubContainer {
    margin: 0.5vh 0;
    padding: 0.8vh 0;
}

.userValidationInfoContainerTitle {
    font-family: var(--poppins-medium);
    font-size: 1.2vw;
    color: var(--dark-grey4);
    height: 3vh;
}

.userValidationInfoContainerP {
    font-family: var(--poppins-light);
    font-size: 1.2vw;
    color: var(--dark-grey4);
    height: 4vh;
}

.userValidationImageContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.userValidationFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6vh;
}

.userValidationFooterBackLink, .userValidationFooterBackLink:hover {
    text-decoration: none;
    color: var(--light-purple);
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
  }

  .userValidationButtonStandard {
    background: none;
    color: var(--dark-grey3);
    border: 1px solid var(--dark-grey3);
    border-radius: 15px;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    padding: 1vh 2.5vw;
  }

  .userValidationButtonStandard:hover {
    background: var(--orange);
    color: var(--white);
    border: var(--orange);
}

  .userValidationButton {
    background: var(--orange);
    color: var(--white);
    border: none;
    border-radius: 15px;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    padding: 1vh 2.5vw;
}

.userValidationBtnContainer {
    display: flex;
    flex-direction: row;
    width: 38vw;
    justify-content: space-between;
}

.userValidationButtonGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30vw;
}

.userDocumentImage {
    width: 30vw;
    margin-bottom: 2vh;
    border-radius: 7px;
}

.userValidationModalIcon {
    width: 2.5vw;
    margin-bottom: 3vh;
  }