#UserEdit{
    position: relative;
    height: 80vh;
}

#UserEdit-content{
    display: flex;
    margin: 5vh 1vw;
}

.userEdit-lside img{
    width: 20vw;
}

.userEdit-rside div{
    margin-bottom: 3vh;
}

.userEdit-rside-inputName{
    font-family: var(--poppins-medium);
    font-size: 1.3vw;
    margin-left: .3vw;
}

.userEdit-rside-input{
    height: 7vh;
    width: 30vw;
    box-shadow: inset 6px 8px 25px #00000029;
    border: 0;
    border-radius: 15px;
    padding: 0 .5vw;
    margin-right: 1vw;
    font-size: 1vw;
}

.userEdit-rside-input::placeholder{
    font-size: 1.3vw !important;
}

.userEdit-rside-btn{
    border: 0;
}