.userRequestLawyers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70vh;
    padding: 0 2vw;
}

.userRequestLawyersInfoContainer {
    background-color: var(--light-grey2);
    display: grid;
    grid-template-columns: 30% 70%;
    border-radius: 7px;
}

.userRequestLawyersInfoImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.userRequestLawyersInfoImage {
    width: 100%;
}

.userRequestLawyersInfoDataContainer {
    padding: 1.2vw 0;
}

.userRequestLawyersInfoTitle {
    font-family: var(--poppins-bold);
    color: var(--light-purple);
    font-size: 1.5vw;
}

.userRequestLawyersInfoDataSubcontainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.2vw 0;
}

.userRequestLawyersComplaintContainer {
    display: flex;
    flex-direction: column;
}

.userRequestLawyersComplaintTitle {
    font-family: var(--poppins-medium);
    color: var(--light-purple);
    font-size: 1.5vw;
    padding: 1.5vw 0;
}

.userRequestLawyersComplaintTextarea {
    background-color: var(--light-grey2);
    border: none;
    border-radius: 7px;
    height: 15vh;
}