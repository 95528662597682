@import "/src/App.css";

.buscardorBotonValidacionesAdmin {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98%;
}

.btnCrearEmpresaValidacionesAdmin {
  width: 15vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: none;
  border: 1px solid var(--orange);
  border-radius: 15px;
  padding: 0.5vh 0;
  font-family: var(--poppins-medium);
  font-size: 3vh;
  background-color: var(--orange);
  color: var(--white);
}

.iconBtnCrearEmpresaAdmin {
  width: 2vw;
}

.searchFieldValidacionesAdminContainer {
  width: 35vw !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  margin: 0 2vw 0 0;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 6px 25px #00000029;
  border-radius: 10px !important;
  opacity: 1;
}

.searchFieldValidacionesAdmin,
.searchFieldValidacionesAdmin:focus {
  height: 6vh;
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}

.searchButtonValidacionesAdmin,
.searchButtonValidacionesAdmin:hover,
.searchButtonValidacionesAdmin:focus {
  background: var(--white) !important;
  box-shadow: none !important;
  border: none !important;
  box-shadow: none !important;
}

.iconLupa {
  width: 1vw !important;
}

::-webkit-input-placeholder {
  font-size: 1.8vh !important;
  color: var(--dark-grey) !important;
  font-family: var(--poppins-light);
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 1.8vh !important;
  color: var(--dark-grey) !important;
  font-family: var(--poppins-light);
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 3vh !important;
  color: var(--dark-grey) !important;
  font-family: var(--poppins-light);
}
