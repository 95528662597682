/* #entityMovements {
    flex: 1;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2vh;
}

.entityMovements-content {
    height: 70vh;
}

.entityMovements-content-tside {
    display: flex;
    height: 30vh;
}

.entityMovements-content-tside-lside {
    background-color: var(--light-grey);
    width: 50%;
    height: 85%;
    padding: 1.2vh 2vw;
}

.entityMovements-content-tside-rside {
    width: 50%;
    height: 85%;
    display: flex;
    justify-content: center;
}

.entityMovements-content-tside-rside img {
    height: 100%;
    background-color: var(--white);
    padding: 2vh 2vw;
    margin-bottom: 4vh;
}

.entityMovements-content-tside-lside h2 {
    font-family: var(--poppins-bold);
    font-size: 1.5vw;
    color: var(--light-purple);
    margin-bottom: 3vh;
}

.entityMovements-content-tside-lside-key {
    font-family: var(--poppins-medium);
    font-size: 1.3vw;
    color: var(--dark-grey4);
}

.entityMovements-content-tside-lside-value {
    font-family: var(--poppins-regular);
    font-size: 1.3vw;
    color: var(--dark-grey4);
}

.entityMovements-content-bside {
    width: 100%;
    height: 55%;
}

.entityMovements-content-bside h2 {
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    color: var(--dark-grey4);
}

.entityMovements-content-bside table {
    width: 100%;
}

.entityMovements-content-bside thead {
    background-color: var(--dark-purple);
    color: var(--white);
    position: absolute;
    left: 0;
    right: 0;
}

.entityMovements-content-bside thead th {
    padding: 2vh 2vw;
    font-family: var(--poppins-light);
    font-size: 1vw;
}

.entityMovements-content-bside thead th:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.entityMovements-content-bside thead th:nth-child(4) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.entityMovements-content-bside tbody {
    background-color: var(--light-grey);
    position: absolute;
    left: 0;
    right: 0;
    top: 7vh;
}

.entityMovements-content-bside tbody td {
    padding: 2vh 2vw;
    font-family: var(--poppins-regular);
    font-size: 1.2vw;
}

.entityMovements-content-bside-bside {
    margin-top: 2vh;
}

.entityMovements-content-bside-bside div {
    background-color: var(--light-grey);
    height: 23vh;
    padding: 2vh 1.5vw;
}

.entityMovements-content-bside-bside p {
    font-family: var(--poppins-regular);
}

.entityMovements-content-footer {
    margin-top: 1vh;
}

.entityMovementsTable {
    height: 100%;
    overflow-y: auto;
    position: relative;
}

.entityMovementsTableRowHead,
.entityMovementsTableRowBody {
    display: flex;
    justify-content: space-around;
} */

#entityMovements {
    width: 95%;
    height: 75vh;
    overflow-y: auto;
    margin: 0 auto;
}

.entityMovements-tside {
    display: flex;
}

.entityMovements-tside-rside {
    background-color: var(--light-grey);
}

.entityMovements-tside-rside img {
    width: 20vw;
}

.entityMovements-tside-lside {
    width: 100%;
}

.entityMovements-tside-lside-header {
    background-color: var(--dark-purple);
    color: var(--white);
    padding: 1.7vh 1.5vw;
    font-family: var(--poppins-regular);
}

.entityMovements-tside-lside-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--light-grey);
    padding: 4vh 1.5vw;
    height: 83%;
    font-size: 1.5vw;
}

.entityMovements-name {
    font-family: var(--poppins-medium);
}

.entityMovements-value {
    font-family: var(--poppins-regular);
}

.entityMovements-tside-lside-info span {
    font-family: var(--poppins-bold);
}

.entityMovements-tside-lside-info img {
    width: 2vw;
    margin-left: 1vw;
}

.entityMovements-bside {
    margin-top: 3vh;
}

.entityMovements-bside h2 {
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    margin-bottom: 2vh;
}

.entityMovements-bside table {
    width: 100%;
}

.entityMovements-bside thead {
    background-color: var(--dark-purple);
    color: var(--white);
}

.entityMovements-bside th,
.entityMovements-bside td {
    padding: 2vh 2vw;
    font-family: var(--poppins-medium);
    font-size: 1.2vw;
}

.entityMovements-bside tbody {
    background-color: var(--light-grey);
}

.entityMovements-bside-companyCard {
    background-color: var(--white);
    display: flex;
    align-items: center;
    width: 15vw;
    padding: 2vh 1vw;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
}

.entityMovements-bside img {
    width: 5vw;
    margin-right: 1vw;
}

.entityMovements-bside p {
    font-family: var(--poppins-medium);
    font-size: 1.2vw;
}

.entityMovements-bside button {
    background-color: var(--orange);
    border: 0;
    border-radius: 10px;
    font-size: 1.6vw;
    padding: 1vh 4vw;
    color: var(--white);
}

.entityMovements-comments p {
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    margin: 2vh 0;
}

.entityMovements-comments textarea {
    width: 100%;
    background-color: var(--light-grey);
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 7px;
    resize: none;
    outline: none;
    border: 0;
    font-family: var(--poppins-light);
    font-size: 1.5vw;
    color: var(--dark-grey4);
    padding: 1vh 1vw;
}

.entityMovements-comments-btnbox {
    display: flex;
    justify-content: space-between;
    width: 30%;
    margin-top: 1vh;
}

.entityMovements-comments-btnbox button,
.entityMovements-comments-btnbox button:disabled {
    background-color: var(--orange);
    color: var(--white);
    border: 0;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    padding: 1.5vh 1.5vw;
    border-radius: 10px;
}

.entityMovements-comments-btnbox button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.entityMovements-footer {
    display: flex;
    justify-content: space-between;
}

.entityMovements-footer-backLink {
    display: flex;
    align-items: center;
    color: var(--light-purple);
    background-color: var(--white);
    border: 0;
    font-family: var(--poppins-medium);
    font-size: 1.7vw;
    margin-top: 1vh;
}

.entityMovements-content-footer-backLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: var(--poppins-medium);
    color: var(--light-purple);
    font-size: 1.5vw;
}

.entityMovements-content-footer-backLink:hover {
    color: var(--light-purple);
}