#UserAttachments {
    margin: 2vh 1vw;
    position: relative;
    min-height: 81vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#UserAttachments-card {
    background-color: var(--light-grey);
    display: flex;
}

.UserAttachments-card-tside {
    margin: 2vh 2vw;
}

.card-rside-title {
    font-family: var(--poppins-bold);
    font-size: 1.5vw;
    color: var(--light-purple);
    margin-bottom: 2vh;
}

.card-rside-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2vh;
    align-items: flex-end;
    color: var(--dark-grey4);
}

.card-rside-grid div {
    width: 20vw;
}

.card-rside-key {
    font-family: var(--poppins-medium);
    font-size: 1.3vw;
}

.card-rside-value {
    font-family: var(--poppins-regular);
    font-size: 1.3vw;
}

.UserAttachments-bside h2 {
    font-family: var(--poppins-medium);
    font-size: 1.3vw;
    margin: 2vh 0;
    color: var(--dark-grey4);
}

.UserAttachments-bside table {
    width: 100%;
}

.UserAttachments-bside thead {
    background-color: var(--dark-purple);
    color: var(--white);
    font-family: var(--poppins-light);
}

.UserAttachments-bside tbody {
    background-color: var(--light-grey);
    font-family: var(--poppins-regular);
}

.UserAttachments-bside th,
.UserAttachments-bside td {
    padding: 1vh 1vw;
    font-size: 1.1vw;
}

.UserAttachments-bside th:nth-child(1) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.UserAttachments-bside th:nth-child(4) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.UserAttachments-bside th:nth-child(1),
.UserAttachments-bside td:nth-child(1) {
    padding-left: 4vw;
    font-size: 1.1vw;
}

.userAttachmentsEyeIcon {
    width: 2vw;
    cursor: pointer;
}

.UserAttachmentsFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1vh;
    padding-bottom: 2vh;
}

.UserAttachmentsFooterBackLink,
.UserAttachmentsFooterBackLink:hover {
    text-decoration: none;
    color: var(--light-purple);
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
}

.UserAttachmentsFooterButton {
    width: 10vw;
    border: 0;
    border-radius: 15px;
    background-color: var(--orange);
    color: var(--white);
    padding: 1vh 0;
    font-size: 1.5vw;
    font-family: var(--poppins-medium);
    margin-left: 1vw;
}