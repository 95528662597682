@import '/src/App.css';

.detalleDBHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 24vW;
    padding: 0 3vw;
  }
  .detalleDBHeaderContainerParagraph {
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    font-family: var(--poppins-bold) !important;
    border-bottom: 5px solid var(--light-purple);
    margin-bottom: -4px;
  }
  .hrDetalleDB {
    color: var(--light-purple) !important;
    background: var(--light-purple);
    height:2px !important;
    opacity: .50 !important;
    margin: 0.1rem 0 2rem 0 !important
  }

.detalleDBMainContainer {
    display: flex;
    flex-direction: column;
    padding: 3.8vh 3vw;
}

#detalleDBSubContainerA{
    display: flex;
    padding: 3vh 3vw;
    background-color: var(--light-grey2);
    border-radius: 30px;
}

#detalleDBSubContainerACard{
    box-shadow: 2px 6px 25px #00000029;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21vw;
    height: 40vh !important; 
    padding: 20px;
    border-radius: 25px;
}

.detalleDBSubContainerACardImg{
    width: 21vw;
    height: auto;
}

#detalleDBInfoContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 2vh 2vw 2vh 0;
}

#detalleDBMainContainerTitle, #detalleDBInfoContainerTitle{
    font-family: "Poppins-Bold";
    font-size: 1.5vw;
    margin-bottom: 3vh;
    color: var(--light-purple);
}

.detalleDBKey{
    font-family: var(--poppins-medium);
}

.detalleDbPContainer {
    width: 15vw;
    text-align: left;
    font-family: var(--poppins-regular);
}

.detalleDBInfoContainerDataRow{
    display: flex;
    justify-content: space-between;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1.5vw;
    color: var(--dark-grey4);
}

#detalleDBSubContainerB h2{
    color: var(--light-purple);
    font-family: var(--poppins-bold);
    font-size: 1.5vw;
    margin-top: 2.5vh;
    margin-left: 2.5vw;
}

#detalleDBSubContainerB-tablebox{
    position: relative;
    box-shadow: inset 0px 3px 6px #00000029, 2px 6px 25px #00000029;
    background-color: var(--light-grey);
    border-radius: 1em;
    margin-top: 3vh;
    padding: 0 3vw;
    overflow-y: auto;
    max-height: 20vh;
}

#detalleDBSubContainerB table{
    width: 100%;
    height: 10%;
}

#detalleDBSubContainerB table thead{
    font-family: "Poppins-bold";
}

#detalleDBSubContainerB table thead th{
    width: 35%;     
    height: 8.5vh;
    color: var(--light-purple);
    font-size: 1.5vw;
}

#detalleDBSubContainerB table thead th:nth-child(4){
    text-align: center;
}

#detalleDBSubContainerB table td{
    padding-bottom: 3vh;
    font-family: var(--poppins-medium);
    color: var(--dark-grey);
    text-align: start;
}

#detalleDBSubContainerB table td:nth-child(4){
    text-align: center;
}

#detalleDBSubContainerB table tr, td{
    text-align: start;
    font-size: 1.55vw;
}

#detalleDBSubContainerB table th:nth-child(5),
#detalleDBSubContainerB table td:nth-child(5){
    text-align: center;
}

#detalleDBSubContainerB img{
    width: 2vw;
}

#detalleDBSubContainerB-bottom{
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
}

#detalleDBSubContainerB-bottom a{
    display: flex;
    align-items: center;
    font-family: var(--poppins-medium);
    text-decoration: none;
}

#detalleDBSubContainerB-bottom p{
    font-size: 1.5vw;
    color: var(--light-purple);
}

.attachmentArchives, .attachmentArchives:hover {
    width: 13vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: none;
    border: 1px solid var(--orange);
    border-radius: 15px;
    padding: 0.5vh 0;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    background-color: var(--orange);
    color: var(--white);
    text-decoration: none;
    margin-left: 3vw;
}

.attachmentArchives a {
    text-decoration: none;
    color: var(--white);
}

.detalleDBFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .detalleDBFooterBackLink, .detalleDBFooterBackLink:hover {
    text-decoration: none;
    color: var(--light-purple);
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    margin-top: 4vh;
  }
  
  .detalleDBFooterButton {
    width: 10vw;
    border: 0;
    border-radius: 15px;
    background-color: var(--orange);
    color: var(--white);
    padding: 1vh 0;
    font-size: 1.5vw;
    font-family: var(--poppins-medium);
    margin-left: 1vw;
  }