@import '/src/App.css';

.bulkUploadHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 24vW;
}

.bulkUploadHeaderParagraph {
  color: var(--light-purple);
  font-size: 1.5vw;
  line-height: 4rem;
  font-family: var(--poppins-bold) !important;
  border-bottom: 5px solid var(--light-purple);
  margin-bottom: -4px;
}

.hrBulkUpload {
  color: var(--light-purple) !important;
  background: var(--light-purple);
  height: 2px !important;
  opacity: .50 !important;
  margin: 0.1rem 0 6rem 0 !important
}

.bulkUploadsTopContent {
  background-color: var(--light-grey);
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
  padding: 1.5vw;
}

.bulkUploadDBImage {
  width: 18vw;
}

.bulkUploadSubContainer1 {
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding-top: 2vh;
  margin: 0 auto;
}

.bulkUploadProgressBarContainer {
  display: flex;
  flex-direction: column;
}

.bulkUploadButtonContainer {
  background: var(--light-grey);
  padding: 2vh 1vw;
  border-radius: 7px;
  text-align: center;
}

.bulkUploadParagraph {
  margin-bottom: 1vh;
  font-family: var(--poppins-medium);
  color: var(--dark-grey3);
  font-size: 1.5vw;
}

.bulkUploadsDropdown {
  height: 45vh;
}

.bulkUploadsDropdownClosed {
  cursor: pointer;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  font-family: var(--poppins-regular);
  padding: 1vh 1vw;
  width: 15vw;
}

.bulkUploadsDropdownClosed img {
  width: 1.5vw;
}

.bulkUploadsDropdownItemList {
  background-color: var(--white);
  font-family: var(--poppins-regular);
  padding: 1vh 0;
  max-height: 40vh;
  overflow-y: auto;
}

.bulkUploadsDropdownItemList p {
  cursor: pointer;
}

.dropActive {
  display: block;
}

.dropDisable {
  display: none;
}

.bulkUploadButtonParagraph {
  margin-bottom: 1vh;
  font-family: var(--poppins-medium);
  color: var(--dark-grey4);
  font-size: 1.5vw;
}

.bulkUploadButton,
.bulkUploadButtonDisabled {
  background: var(--orange);
  color: var(--white);
  border: none;
  border-radius: 15px;
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
  padding: 1vh 2.5vw;
}

.bulkUploadButtonDisabled {
  opacity: 0.5;
}

.bulkUploadButtonDisabled:hover {
  cursor: not-allowed;
}

.bulkUploadButton:hover {
  cursor: pointer;
}

.bulkUploadChargeType {
  font-family: var(--poppins-medium);
  color: var(--dark-grey4);
  font-size: 1.5vw;
  margin-top: 4vh;
}

.bulkInput {
  display: none;
}

.bulkUploadDropdownItem {
  height: 1.5vw;
  padding: 0 1vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bulkUploadDropdownItem:hover {
  background-color: var(--light-grey);
}