@import '/src/App.css';

.createNewUserCardBody, .createNewUserCardBody:hover {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 22vw;
    height: 45vh;
    border-radius: 35px;
    padding: 1vh 2vw;
    text-decoration: none;
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1.6vw;
}

.editNewUserCardBody, .editNewUserCardBody:hover {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 16vw;
    height: 30vh;
    border-radius: 30px;
    padding: 1vh 2vw;
    text-decoration: none;
    color: var(--white);
    font-family: var(--quicksand-bold);
    font-size: 1.1vw;
}

.newUserCardParagraph {
    width: min-content;
}

.newUserCardImage {
    align-self: flex-end;
    width: 13vw;
    position: relative;
    left: 3vw;
    top: 30px;
    opacity: 0.5;
}

.newUserCardBodyBGOrange {
    background: var(--orange);
}

.newUserCardBodyBGDarkPurple {
    background: var(--dark-purple);
}

.newUserCardImgSizeAbogado {
    width: 15vw;
    position: relative;
    left: 3vw;
}

.newUserCardBodyBGLightPurple {
    background: var(--light-purple);
}

.newUserCardImgSizeOperativo {
    width: 15vw;
    position: relative;
    left: 5vw;
    top: 6vh;
}