#MovimientosTuDataControl {
    width: 95%;
    height: 75vh;
    overflow-y: auto;
    margin: 0 auto;
}

.MovimientosTuDataControl-tside {
    display: flex;
}

.MovimientosTuDataControl-tside-rside {
    background-color: var(--light-grey);
}

.MovimientosTuDataControl-tside-rside img {
    width: 20vw;
}

.MovimientosTuDataControl-tside-lside {
    width: 100%;
}

.MovimientosTuDataControl-tside-lside-header {
    background-color: var(--dark-purple);
    color: var(--white);
    padding: 1.7vh 1.5vw;
    font-family: var(--poppins-regular);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.MovimientosTuDataControl-tside-lside-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--light-grey);
    padding: 4vh 1.5vw;
    height: 83%;
    font-size: 1.5vw;
}

.MovimientosTuDataControl-name {
    font-family: var(--poppins-medium);
}

.MovimientosTuDataControl-value {
    font-family: var(--poppins-regular);
}

.MovimientosTuDataControl-tside-lside-info span {
    font-family: var(--poppins-bold);
}

.MovimientosTuDataControl-tside-lside-info img {
    width: 2vw;
    margin-left: 1vw;
}

.MovimientosTuDataControl-bside {
    margin-top: 3vh;
}

.MovimientosTuDataControl-bside h2 {
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    margin-bottom: 2vh;
}

.MovimientosTuDataControl-bside table {
    width: 100%;
}

.MovimientosTuDataControl-bside thead {
    background-color: var(--dark-purple);
    color: var(--white);
}

.MovimientosTuDataControl-bside th,
.MovimientosTuDataControl-bside td {
    padding: 1.5vh 2vw;
    font-family: var(--poppins-light);
    font-size: 1.2vw;
}

.MovimientosTuDataControl-bside tbody {
    background-color: var(--light-grey);
}

.MovimientosTuDataControl-bside-companyCard {
    background-color: var(--white);
    display: flex;
    align-items: center;
    width: 15vw;
    padding: 2vh 1vw;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
}

.MovimientosTuDataControl-bside img {
    width: 5vw;
    margin-right: 1vw;
}

.MovimientosTuDataControl-bside p {
    font-family: var(--poppins-medium);
    font-size: 1.2vw;
}

.MovimientosTuDataControl-bside button {
    background-color: var(--orange);
    border: 0;
    border-radius: 10px;
    font-size: 1.6vw;
    padding: 1vh 4vw;
    color: var(--white);
}

.MovimientosTuDataControl-comments p {
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    margin: 2vh 0;
}

.MovimientosTuDataControl-comments textarea {
    width: 100%;
    background-color: var(--light-grey);
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 7px;
    resize: none;
    outline: none;
    border: 0;
    font-family: var(--poppins-light);
    font-size: 1.5vw;
    color: var(--dark-grey4);
    padding: 1vh 1vw;
}

.MovimientosTuDataControl-comments-btnbox {
    display: flex;
    justify-content: flex-end;
    margin-top: 1vh;
}

.MovimientosTuDataControl-comments-btnbox button,
.MovimientosTuDataControl-comments-btnbox button:disabled {
    background-color: var(--orange);
    color: var(--white);
    border: 0;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    padding: 0.8vh 1.5vw;
    border-radius: 0.7vw;
    margin-left: 2vw;
}

.MovimientosTuDataControl-comments-btnbox button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.MovimientosTuDataControl-footer-backLink {
    display: flex;
    align-items: center;
    color: var(--light-purple);
    background-color: var(--white);
    border: 0;
    font-family: var(--poppins-medium);
    font-size: 1.7vw;
    margin-top: 1vh;
}