#RejectNotAllowedContact{
    padding: 0 2vw;
}

.RejectNotAllowedContactTSide{
    display: flex;
}

.RejectNotAllowedContactTSideLside{
    background-color: var(--light-grey);
    padding: 3vh 1vw;
    width: 35%;
    height: 30vh;
}

.RejectNotAllowedContactTSideLside h2{
    font-family: var(--poppins-bold);
    color: var(--light-purple);
    font-size: 1.5vw;
}

.RejectNotAllowedContactTSideLside p{
    font-size: 1vw;
    font-family: var(--poppins-light);
    color: var(--dark-grey3);
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 20vh;
}

.RejectNotAllowedContactTSideRside{
    padding: 3vh 2.5vw;
}

.RejectNotAllowedContactTSideRside h2{
    font-family: var(--poppins-bold);
    color: var(--light-purple);
    font-size: 1.5vw;
}

.RejectNotAllowedContactTSideRside p{
    font-family: var(--poppins-light);
    color: var(--orange);
    font-size: 1vw;
}

.boldText{
    font-family: var(--poppins-bold);
}

.RejectNotAllowedContactBSide{
    margin-top: 1.2vh;
}

.RejectNotAllowedContactBSide h2{
    font-family: var(--poppins-medium);
    color: var(--dark-grey3);
    font-size: 1.5vw;
}

.RejectNotAllowedContactBSide textarea{
    background-color: var(--light-grey);
    border: 1px solid var(--dark-grey3);
    border-radius: 15px;
    width: 100%;
    height: 27vh;
    font-size: 1.2vw;
    padding: 1vh 1vw;
    color: var(--dark-grey3);
    font-family: var(--poppins-regular);
}

.RejectNotAllowedContactBSide span{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-family: var(--poppins-light);
    color: var(--dark-grey2);
}

.RejectNotAllowedContactSendBtn{
    width: 12vw;
    height: 6vh;
    border: 0;
    border-radius: 10px;
    font-size: 1.3vw;
    font-family: var(--poppins-medium);
    color: var(--white);
    background-color: var(--orange);
}

.RejectNotAllowedContactSendBtn:disabled{
    background-color: var(--white);
    color: var(--dark-grey);
    border: 1px solid var(--dark-grey2);
}