#HistoryPaysDetails {
    height: 60vh;
}

.HistoryPaysDetailsBox {
    display: flex;
    gap: 1vw;
    width: 95%;
    margin: 0 auto;
}

.HistoryPaysDetails-lside {
    width: 30%;
    background-color: var(--light-grey);
    padding: 2vh 2vw;
}

.HistoryPaysDetails-lside div {
    margin-bottom: 2vh;
    font-size: 1.4vw;
}

.HistoryPaysDetails-lside-title {
    font-family: var(--poppins-bold);
    font-size: 1.5vw;
    color: var(--light-purple);
}

.HistoryPaysDetails-lside-key {
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
}

.HistoryPaysDetails-lside-value {
    font-family: var(--poppins-regular);
    color: var(--dark-grey3);
}

.HistoryPaysDetails-rside {
    width: 70%;
}

.HistoryPaysDetails-rside-title {
    font-family: var(--poppins-bold);
    font-size: 1.5vw;
    color: var(--light-purple);
    margin-top: 1vh;
    margin-bottom: 4vh;
}

.HistoryPaysDetails-rside table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 .8vh;
}

.HistoryPaysDetails-rside table thead {
    background-color: var(--dark-purple);
    color: var(--white);
    font-family: var(--poppins-light);
    font-size: 1vw;
}

.HistoryPaysDetails-rside table thead th {
    padding: 2vh 1.7vw;
}

.HistoryPaysDetails-rside table tbody {
    background-color: var(--light-grey);
    font-family: var(--poppins-light);
    color: var(--dark-grey4);
}

.HistoryPaysDetails-rside table tbody td {
    padding: 2vh 1.2vw;
    font-size: 1vw;
}

.HistoryPaysBackBox {
    background: transparent;
    position: absolute;
    display: flex;
    align-items: center;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    border: 0;
    bottom: 5vh;
    margin-left: 2vw;
}

.HistoryPaysBackBox img {
    height: 5vh;
}

.HistoryPaysBackBox span {
    color: var(--light-purple);
}

.HistoryPaysDetailsTableContainer {
    max-height: 60vh;
    overflow-y: auto;
}