.dataBaseLayoutContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.listaDBHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 24vW;
    margin-left: 2vw
}

.ListaDBHeaderParagraph {
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    font-family: var(--poppins-bold) !important;
    border-bottom: 8px solid var(--light-purple);
    margin-bottom: -4px;
}

.hrListaDB {
    position: relative;
    top: -3px;
    color: var(--light-purple) !important;
    background: var(--light-purple);
    height: 2px !important;
    opacity: .50 !important;
    /* margin: 0.1rem 0 !important; */
    margin: 0 !important
}