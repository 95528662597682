.contactLayout {
    height: 90vh;
}

.headerContainerContact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 95%;
    margin-left: 2vw;
}

.ContactAdminContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.ContactAdminParagraph {
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    font-family: var(--poppins-bold) !important;
    border-bottom: 5px solid var(--light-purple);
    margin-bottom: -4px;
}

.hrContactAdmin {
    color: var(--light-purple) !important;
    background: var(--light-purple);
    height: 2px !important;
    opacity: 0.5 !important;
    margin: 0.1rem 0 !important;
    margin-bottom: 3vh !important;
}