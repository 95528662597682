@import '/src/App.css';

.deleteUserHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 24vW;
}

.deleteUserHeaderParagraph {
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    font-family: var(--poppins-bold) !important;
    border-bottom: 5px solid var(--light-purple);
    margin-bottom: -4px;
}

.hrDeleteUser {
    color: var(--light-purple) !important;
    background: var(--light-purple);
    height: 2px !important;
    opacity: .50 !important;
    /* margin: 0.1rem 0 !important; */
    margin: 0.1rem 0 6rem 0 !important
}

.deleteUserSubContainer {
    flex: 1;
    padding: 0 1.5vw 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2vh;
}

.deleteUserH3 {
    font-family: var(--poppins-bold);
}

.deleteUserTextArea {
    resize: none;
    width: 74vw;
    height: 30vh;
    border: none;
    background: var(--light-grey2);
    border-radius: 7px;
    font-family: var(--poppins-light);
    font-size: 1.5vw;
    color: var(--dark-grey4);
    padding: 1vh 1vw;
}

.deleteUserParagraph1 {
    font-family: var(--poppins-regular);
    font-size: 1.5vw;
    margin-bottom: 2vh;
}

.deleteUserFooterContainer {
    display: flex;
    flex-direction: column;
    width: 74vw;
    height: 20vh;
    justify-content: center;
}

.deleteUserFooterSubContainer {
    width: 74vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.deleteUserParagraph2 {
    font-family: var(--poppins-regular);
    font-size: 1.3vw;
}

.deleteUserBtnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 25vw;
    margin-top: 2vh;
}

.deleteUserButton,
.deleteUserButton:disabled,
.deleteUserButtonDisabled {
    width: 12vw;
    background: var(--orange);
    color: var(--white);
    border: none;
    border-radius: 15px;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    padding: 1vh 2.5vw;
}

.deleteUserButton:disabled,
.deleteUserButtonDisabled,
.deleteUserButtonDisabled:hover {
    cursor: not-allowed;
    opacity: 0.5;
}

.deleteUserFooterBackLink,
.deleteUserFooterBackLink:hover {
    text-decoration: none;
    color: var(--light-purple);
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
}

.deleteUserIconOK {
    width: 2.5vw;
}