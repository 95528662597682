.imageModal {
    background: rgba(119, 36, 86, 0.6);
    backdrop-filter: blur(5px);
    position: fixed;
    z-index: 4;
    top: -15%;
    left: -15%;
    right: -15%;
    bottom: -15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageModalBody {
    width: 40%;
    height: 40%;
}

.imageModalTitle {
    color: var(--white);
    background-color: var(--dark-purple);
    margin: 0;
    width: 50%;
    padding: 1vw;
    font-family: var(--poppins-bold);
    font-size: 1.5vw;
    text-align: center;
    border-radius: 0.7vw 0.7vw 0 0;
}

.imageModalContent {
    width: 100%;
    height: 100%;
    background-color: var(--white);
    border-radius: 0.7vw 0.7vw;
}

.imageModalContent img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.imageModalButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.imageModalButtonsOperativo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}