.CreateNewCompanyImg{
    width: 15vw;
    margin-bottom: 3vh;
}

.CreateNewCompanyFormGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2vh 4vw;
}

.CreateNewCompanyFormItemText{
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
    font-size: 1.5vw;
}

.CreateNewCompanyFormItemInput{
    border: 0;
    box-shadow: inset 6px 8px 25px #00000029;
    width: 100%;
    height: 6vh;
    border-radius: 10px;
    font-size: 1.3vw;
    font-family: var(--poppins-light);
    color: var(--dark-grey3);
    padding: 0 1vw;
}

.CreateNewCompanyFormItem input[type='file']{
    display: none;
}

.UploadLogoButton{
    background-color: var(--orange);
    color: var(--white);
    font-family: var(--poppins-medium);
    font-size: 1.4vw;
    width: 50%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.inputIsWrong{
    border: 1px solid var(--red);
}

.labelIsWrong{
    font-size: .9vw;
    color: var(--red);
    margin: 0;
}

.CreateNewCompanyLogoItem {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    margin-top: 4vh;
}

.createCompanyLoadedImageContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5vw;
    margin-bottom: 1vh;
    max-width: 40%;
    margin-left: 2vw;
}

.createCompanyLoadedImage {
    width: 1.5vw;
}

.createCompanyLoadedImageText {
    font-family: var(--poppins-light);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
