.editNotAllowedContactTable {
    width: 98%;
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0 1vh;
    border-collapse: collapse;
}

.editNotAllowedContactRowHead {
    background-color: var(--dark-purple);
    color: var(--white);
    font-family: var(--poppins-light);
    font-size: 1vw;
}

.editNotAllowedContactCellHead {
    padding: 2vh 1.4vw;
    position: relative;
    text-align: center;
    width: 50%;
}

.editNotAllowedContactTbody {
    overflow-y: auto;
    max-height: 50vh;
}

.notAttachmentsFoundMessage{
    width: 22vw;
    font-family: var(--poppins-bold);
    color: var(--dark-grey4);
    font-size: 1.3vw;
    margin-top: 2vh;
}

.rejectButton{
    width: 10vw;
}