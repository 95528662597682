.SelectProcessBox{
    display: flex;
    gap: 1vw;
    margin-bottom: 1.5vh;
}

.SelectProcessLeftSide{
    background-color: var(--light-grey);
    height: 30vh;
    padding: 2vh 2vw;
    width: 54%;
    border-radius: 11px;
    box-shadow: 0px 3px 6px #00000029;
}

.SelectProcessRightSide{
    background-color: var(--light-grey);
    height: 30vh;
    padding: 2vh 2vw 4vh;
    width: 45%;
    border-radius: 11px;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.SelectProcessBoxTitle{
    color: var(--light-purple);
    font-size: 1.56vw;
    font-family: var(--poppins-bold);
}

.NoAnnexes{
    margin-top: 10vh;
}

.SelectProcessInputBox{
    display: flex;
    align-items: center;
}

.SelectProccessCheck{
    width: 1.3vw;
    height: 1.3vw;
    appearance: none;
    background-color: #fff;
    border: 1px solid var(--orange);
    border-radius: 3px;
    cursor: pointer;
    margin-right: .5vw;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    background-size: 1.5vw;
    background-position: center;
}

.SelectProccessCheck:checked{
    background-color: var(--orange);
}

.SelectProcessBoxText{
    font-family: var(--poppins-regular);
    font-size: 1.25vw;
    color: var(--dark-grey4);
}


.SelectProcessBoxFile{
    display: flex;
}

.SelectProcessBoxFileName{
    text-overflow: ellipsis;
}

.SelectProcessBoxFileRemoveBtn{
    border: 0;
    width: .9vw;
    margin-left: .5vw;
}

.SelectProcessBoxFileRemoveBtn img{
    width: 100%;
}

.SelectProcessBtnBox{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1vh;
}

#annexesFilesInput{
    display: none;
}

.SelectProcessUploadButton{
    background-color: var(--orange);
    border: 0;
    color: #fff;
    font-family: var(--poppins-medium);
    font-size: 1.56vw;
    width: 18vw;
    padding: .7vh 0;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.SelectProcessUploadButtonImg{
    width: 1.3vw;
    margin-left: 1vw;
}

.SelectProcessSendButton{
    background-color: var(--orange);
    border: 0;
    color: #fff;
    font-family: var(--poppins-medium);
    font-size: 1.56vw;
    width: 18vw;
    padding: .7vh 0;
    border-radius: 10px;
}

.SelectProcessSendButton:disabled{
    background-color: #fff;
    border: 1px solid var(--dark-grey);
    color: var(--dark-grey2);
}
