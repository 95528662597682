#CaseTrackingDetail{
    display: flex;
    padding: 0 2vw;
    height: 65vh;
    gap: 1.8vw;
}

.CaseTrackingDetailLSide{
    background-color: var(--light-grey);
    padding: 3vh 2vw;
    border-radius: 15px;
    width: 25vw;
}

.CaseTrackingDetailItemBox{
    margin-bottom: 2.8vh;
    line-height: 2.7vh;
}

.CaseTrackingDownloadBtn{
    display: flex;
    background-color: var(--orange);
    border: 0;
    color: var(--white);
    padding: 1vh 1vw;
    font-family: var(--poppins-medium);
    font-size: 1vw;
    border-radius: 10px;
}

.CaseTrackingDownloadBtn img{
    width: 1.3vw;
    margin-right: .5vw;
}

.CaseTrackingDetailTitle{
    font-family: var(--poppins-bold);
    font-size: 1.5vw;
    color: var(--light-purple);
}

.expiredAlertTrackingDetail{
    position: absolute;
    bottom: 1px;
    left: 5vw;
}

.expiredAlertText{
    margin-right: .2vw;
}

.expiredAlertTrackingDetail img{
    width: 1vw;
}

.expiredAlertText{
    color: var(--red);
    font-size: 1vw;
}

.CaseTrackingDetailKey{
    font-family: var(--poppins-medium);
    font-size: 1.25vw;
    color: var(--dark-grey4);
}

.CaseTrackingDetailValue{
    font-family: var(--poppins-regular);
    font-size: 1.25vw;
    color: var(--dark-grey4);
}

.CaseTrackingDetailRSide{
    background-color: var(--light-grey);
    padding: 3vh 2vw;
    border-radius: 15px;
    width: 45vw;
}

.CaseTrackingCompanyList{
    overflow-y: auto;
    max-height: 90%;
    padding-right: 1vh;
}

.CaseTrackingDetailCompanyCard{
    position: relative;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 1.5vh 1vw;
    border-radius: 10px;
    margin-bottom: .7vh;
    box-shadow: 0px 3px 6px #00000029;
}

.CaseTrackingDetailCompanyCard div{
    display: flex;
    align-items: center;
    font-size: 1.25vw;
    font-family: var(--poppins-regular);
    color: var(--dark-grey4);
}

.CaseTrackingCompanyIcon{
    width: 3vw;
    height: 3vw;
    margin-right: 1vw;
}

.CaseTrackingDetailArrow{
    width: 2vw;
    transform: rotate(-90deg);
}

.CaseTrackingDetailBtn{
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
}
