@import '/src/App.css';

.mainContainerBaseAdministrativo {
    background-size: cover;
    height: 100vh;
    min-height: 100%;
    background-repeat: no-repeat;
    /* background: linear-gradient(0eg, rgba(72, 17, 53, 1) 0%, rgba(115, 21, 79, 1) 100%); */
    background-Image: url('../../assets/images/huella_Mesa_de_trabajo_1.png');
    background-color: #59113d;
    background-position-x: -50vw;
    background-position-y: -8vh;
    background-size: 83vw;
}

.colMenuAdministrativo {
    text-align: center;
    padding: 0 !important;
    width: 21% !important;
    /* height: 100vh; */
    /* min-height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.subContainerMenuAdministrativo1 {
    padding-top: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 60%; */
}

.mainLogoContainer {
    width: 17vw;
    display: flex;
    flex-direction: column;
    margin: 3vh auto;
    align-items: flex-end;
}

.logoParagraph {
    color: var(--white);
    font-size: 1vw;
    font-family: var(--quicksand-bold);
}

.currentRoleContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5.5vw;
    position: relative;
}

.currentRoleSelector{
    width: 1vw;
    height: 1vw;
}

.currentRoleButton{
    background-color: var(--white);
    width: 100%;
    height: 100%;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.currentRoleButton img{
    width: 80%;
}

.currentRoleOptions{
    background-color: var(--white);
    width: 11.5vw;
    font-family: var(--poppins-medium);
    position: absolute;
    left: 1px;
    top: 3.5vh;
    margin-left: 5.5vw;
    text-align: start;
    padding: 1vh 0;
    border-radius: 10px;
}

.currentRoleOption{
    color: var(--orange);
    padding: .2vh 1vw;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    width: 100%;
    text-align: start;
}

.currentRoleOption:hover{
    background-color: var(--orange);
    color: var(--white);
}

.baseAdministrativoMenu {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    width: 100%;
}

.subContainerMenu2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    /* height: 40%; */
}

.logoutContainer {
    color: var(--white);
    display: flex;
    padding: 1.5vh 0vw;
    margin-bottom: 1.5vh;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    text-decoration: none;
    font-family: var(--quicksand-bold);
    font-size: 1.5vw;
    cursor: pointer;
}

.logoutContainer:hover {
    color: var(--white);
    background: rgba(255, 93, 0, 0.7);
    cursor: pointer;
}

.logoutContainer>img {
    width: 10%;
}

.colDatos {
    background: #EEEEEE;
    width: 79% !important;
    height: 100vh;
    min-height: 100%;
    margin-right: 0;
    padding: 0 !important;
}

.logoApp {
    width: 100%;
}

.navbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 1.2vh 0;
    text-decoration: none;
}

.navbar-item:hover {
    background: rgba(255, 93, 0, 0.85);
}

.item-selected {
    background: rgba(255, 93, 0, 0.7);
}

.iconoPersonaBaseA {
    width: 15%;
}

.iconoPersonaBaseA--small {
    width: 12%;
}

.paragraphSolicitudes,
.paragraphValidaciones,
.paragraphUsuarios,
.paragraphPQRS,
.paragraphAjustes {
    font-family: var(--quicksand-bold);
    font-size: 1.5vw;
    margin: 0;
    color: #FFFFFF;
    width: 54%;
    text-align: left;
    line-height: 120%;
}

.subContainer {
    background: #FFFFFF;
    border-radius: 35px;
    height: 93vh;
    margin: 2rem 1rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media (min-width: 576px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 100vw !important;
    }
}