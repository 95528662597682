.notAllowedContact {
    padding: 0 1vw;
    padding-bottom: 5vh;
}

.notAllowedContactHeader {
    padding: 0 3vw;
    display: grid;
    grid-template-columns: 15% 25% 20% 20% 20%;
    background-color: var(--dark-purple);
    font-family: var(--poppins-light);
    color: var(--white);
    border-radius: 0.5vw;
}

.notAllowedContactHeaderCell {
    display: flex;
    align-items: center;
    padding: 0 1vw;
    height: 7.5vh;
    font-size: 2vh;
}

.notAllowedContactHeaderCell:nth-child(4),
.notAllowedContactHeaderCell:nth-child(5){
    justify-content: center;
    text-align: center;
}

.notAllowedContactBody {
    margin-top: 1.5vh;
    height: 65vh;
    overflow-y: auto;
}