#UserMovements {
  flex: 1;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2vh;
}

.UserMovements-content {
  display: flex;
}

.UserMovements-content-lside {
  background-color: var(--light-grey);
  padding: 1vh 1vw;
  width: 30vw;
  max-height: 25vh;
}

.UserMovements-content-lside img {
  width: 20vw;
}

.UserMovements-content-lside-data h2 {
  font-family: var(--poppins-bold);
  font-size: 1.5vw;
  color: var(--light-purple);
  margin-bottom: 2vh;
}

.UserMovements-content-lside-key {
  margin-top: 1vh;
  font-family: var(--poppins-medium);
  font-size: 1.3vw;
  color: var(--dark-grey4);
}

.UserMovements-content-lside-value {
  font-family: var(--poppins-regular);
  font-size: 1.3vw;
  color: var(--dark-grey4);
}

.UserMovements-content-rside {
  margin: 0 2vw;
  width: 100%;
}

.UserMovements-content-rside h2 {
  font-family: var(--poppins-bold);
  color: var(--light-purple);
  font-size: 1.5vw;
}

.UserMovements-content-rside table {
  width: 100%;
}

.UserMovements-content-rside thead {
  background-color: var(--dark-purple);
  color: var(--white);
}

.UserMovements-content-rside thead th {
  padding: 2vh 1.5vw;
  font-family: var(--poppins-light);
  font-size: 1vw;
}

.UserMovements-content-rside thead th:nth-child(1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.UserMovements-content-rside thead th:nth-child(3) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.UserMovements-content-rside tbody {
  background-color: var(--light-grey);
}

.UserMovements-content-rside tbody td {
  padding: 1.3vh 3vh;
  font-family: var(--poppins-regular);
  font-size: 1.2vw;
  color: var(--dark-grey4);
}

.UserMovements-footer {
  margin-top: 1.3vh;
  font-family: var(--poppins-medium);
  color: var(--light-purple);
  text-decoration: none;
}

.UserMovements-TableContainer {
  height: 60vh;
  overflow-y: auto;
}