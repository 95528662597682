#handlePQRSContent {
  margin: 0 2vw;
}

#handlePQRSHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--poppins-light);
  margin-bottom: 3vh;
}

#handlePQRSHeaderStatusSpan {
  background-color: var(--orange);
  color: var(--white);
  padding: 0.5vh 1vw;
  border-radius: 20px;
  margin-right: 0.5vw;
}

#handlePQRSHeader h2 {
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
}

#handlePQRSHeaderAsignedTo {
  color: var(--orange);
  display: flex;
}

#handlePQRSGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

#handlePQRSGrid p,
#handlePQRSGrid span {
  font-family: var(--poppins-medium);
}

.clipIcon {
  width: 1vw;
  margin-left: 0.5vw;
}

.handlePQRSTextareaReadOnly {
  border: 2px solid var(--light-purple);
  font-size: 1.1vw;
  border-radius: 10px;
  width: 100%;
  padding: 1vh 1vw;
}

.handlePQRSTextarea {
  box-shadow: inset 6px 8px 25px #00000029;
  border-radius: 13px;
  padding: 1vh 1vw;
  border: 0;
  width: 100%;
}

.handlePQRSTextareaReadOnly::-webkit-scrollbar {
  width: 10px;
}

.handlePQRSTextareaReadOnly::-webkit-scrollbar-thumb {
  background-color: var(--light-purple);
  border-radius: 25px;
}

.handlePQRSTextareaReadOnly::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: var(--dark-purple);
}

.handlePQRSTextareaQueryCard {
  border: 0;
  border-radius: 8px;
  padding: 1vh 1vw;
  background: var(--light-grey);
  box-shadow: inset 0px 3px 6px #00000029;
  width: 100%;
}

.cancelFooterButton {
  width: 10vw;
  border: 2px solid var(--dark-grey2);
  border-radius: 15px;
  background-color: var(--white);
  color: var(--dark-grey2);
  padding: 1vh 2vw;
  font-size: 1.3vw;
  font-family: var(--poppins-medium);
  margin-right: 0.5vw;
}
