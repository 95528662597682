.userRequestDataContainer {
    margin-bottom: 1vw;
}

.userRequestDataTitle {
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
    font-size: 1.5vw;
    margin: 0;
}

.userRequestDataParagraph {
    font-family: var(--poppins-regular);
    color: var(--dark-grey4);
    font-size: 1.3vw;
}