.App {
    height: 100vh;
    overflow: hidden;
}

.loginContainer {
    background-image: url('../../assets/images/fondo_general_naranja.png');
    background-size: cover;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    margin: 0;
    overflow: hidden;
    height: 100vh;
}

.loginSubcontainer1 {
    align-content: space-between;
    align-items: center;
    height: 100vh !important;
    width: 50vw !important;
    display: grid;
    padding: 4vh 0;
}

.loginLogo {
    margin-top: 3vw;
    margin-left: 3vw;
    height: 9vh;
}

.loginTitle {
    font-family: var(--quicksand-bold);
    font-size: 3.13vw;
    color: var(--white);
    margin-left: 3vw;
    margin-bottom: 2vw;
}

.loginBackContainer {
    height: 3vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* Segunda columna */
.loginSubcontainer2 {
    color: var(--white);
    height: 100vh !important;
    width: 50vw !important;
    box-sizing: border-box;
    display: grid;
    align-content: center;
    justify-content: center;
    padding: 0;
    padding-right: 9vw;
}

.loginSubtitle {
    font-family: var(--poppins-bold);
    font-size: 3.13vw;
}

.loginDescription {
    font-family: var(--poppins-light);
    font-size: 1.36vw;
    margin-bottom: 10vh;
    color: var(--white);
    font-weight: bold;
}

.loginUserInputContainer {
    color: var(--dark-grey4);
    position: relative;
    font-family: var(--poppins-regular);
}

.loginUserInputContainer input {
    border-radius: 0.5vw;
    height: 8vh !important;
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem);
}

.loginUserInputContainer label {
    font-family: var(--poppins-light);
    color: var(--dark-grey4);
    font-size: 1.36vw;
}

.loginUserPasswordContainer {
    display: flex;
    flex-direction: column;
}

.loginIconEye {
    width: 2.5vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
}

.loginAditionaOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--white);
}

.loginRememberData {
    font-family: var(--poppins-light);
    font-size: 2vw;
}

.loginRememberData>label {
    color: var(--white);
    padding: 0;
    font-size: 1.36vw;
}

.loginForgetPassword,
.loginForgetPassword:hover {
    font-family: var(--poppins-medium);
    font-size: 1.35vw;
    text-decoration-line: none;
    color: var(--white);
}

.loginButton {
    font-family: var(--poppins-medium);
    font-size: 1.6vw;
    border-radius: 13px;
    padding: 1vh 0;
    margin-top: 8vh;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    background-color: var(--light-purple);
    color: var(--white);
    border: 100px;
    width: 100%;
}

.loginButton:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.loginLogoContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.loginContainerLogoImg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.loginPortalRole {
    margin-top: -0.5vh;
    color: var(--white2);
    font-family: var(--quicksand-bold);
    font-size: 0.63vw;
}

.loginForm {
    width: 36vw;
}

.loginWarningIconContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.loginRightFormat {
    display: none;
}

.loginIncorrectFormat {
    display: grid;
    grid-template-columns: 8% 92%;
    margin-bottom: 0.5vw;
    color: var(--white) !important;
    font-size: 1.355vw !important;
    font-family: var(--poppins-light);
}

.logininputFormatMessage {
    display: flex;
    align-items: center;
}