.unregisteredUserContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5vh;
}

#unregisteredUserTable {
  width: 98%;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 1vh;
}

#unregisteredUserTable th,
#unregisteredUserTable td {
  padding: 0 1vw;
  height: 7.5vh;
  font-size: 2vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#unregisteredUserTableHead {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark-purple);
  color: var(--white);
  font-family: var(--poppins-light);
  font-size: 1vw;
  align-items: center;
}

#unregisteredUserTableBody {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: var(--poppins-light);
  color: var(--dark-grey3);
  background-color: var(--light-grey);
  margin: 1.5vh 0;
  border-radius: 10px;
  justify-content: space-between;
  font-size: 1vw;
  align-items: center;
}

#unregisteredUserTableHead th:nth-child(1),
#unregisteredUserTableBody td:nth-child(1) {
  width: 5%;
}

#unregisteredUserTableHead th:nth-child(2),
#unregisteredUserTableBody td:nth-child(2) {
  width: 25%;
}

#unregisteredUserTableHead th:nth-child(3),
#unregisteredUserTableBody td:nth-child(3) {
  width: 10%;
}

#unregisteredUserTableHead th:nth-child(4),
#unregisteredUserTableBody td:nth-child(4) {
  width: 30%;
  text-align: center;
}

#unregisteredUserTableHead th:nth-child(5),
#unregisteredUserTableBody td:nth-child(5) {
  width: 15%;
  text-align: center;
}

#unregisteredUserTableHead th:nth-child(6),
#unregisteredUserTableBody td:nth-child(6) {
  width: 5%;
  text-align: center;
}

#unregisteredUserTableHead th:nth-child(7),
#unregisteredUserTableBody td:nth-child(7) {
  width: 10%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#unregisteredUserTableBody td {
  font-family: var(--poppins-light);
  font-size: 2vh;
  color: var(--dark-grey4);
  text-align: start;
}

#unregisteredUserTableBody button {
  border: 0;
}

.editIcon {
  width: 2vw;
}

.carousel-controls {
  width: 20%;
  margin: 0 auto;
  text-align: center;
}