#ProceedNotAllowedContact {
  padding: 0 2vw 3vh 2vw;
  line-height: 1.2;
  overflow-y: auto;
  max-height: 90%;
}

.RequestSendTitles {
  font-family: var(--poppins-bold);
  font-size: 1.6vw;
  color: var(--black2);
  margin-bottom: 3vh;
}

.RequestSendSubTitle1 {
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
  color: var(--dark-grey4);
}

.RequestSendDesc1 {
  font-family: var(--poppins-light);
  font-size: 1.5vw;
  color: var(--dark-grey4);
}

.RequestSendContent {
  margin: 3vh 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6vw;
}

.RequestSendInfoTextarea {
  width: 100%;
  height: 20vh;
  resize: none;
  background: var(--light-grey);
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 7px;
  border: none;
  font-family: var(--poppins-light);
  font-size: 1.5vw;
  color: var(--dark-grey4);
  padding: 1vh 1vw;
}

.RequestSendBottom {
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
}

.RequestSendBottomBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1vh;
}

.RequestSendBottomBox input[type='file'] {
  display: none;
}

.RequestSendBottomBox label {
  cursor: pointer;
}

.RequestSendBtn:disabled {
  background-color: var(--white);
  border: 1px solid var(--dark-grey);
  color: var(--dark-grey);
}

.RequestSendInfoTitle {
  font-family: var(--poppins-medium);
  font-size: 1.6vw;
  color: var(--black2);
}

.RequestSendBottomBoxText {
  font-family: var(--poppins-regular);
  font-size: 1.6vw;
  color: var(--dark-grey4);
}

.RequestSendBtn {
  background-color: var(--orange);
  width: 20vw;
  border: none;
  font-family: var(--poppins-medium);
  font-size: 1.6vw;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh 0;
  border-radius: 0.8vw;
}

.RequestSendAuxContainer {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2vw;
}

.RequestUploadedFileName {
  white-space: nowrap;
  overflow: hidden;
  max-width: 15vw;
  font-size: 1.3vw;
  text-overflow: ellipsis;
  font-family: var(--poppins-regular);
  color: var(--dark-grey3);
  text-align: right;
}

.RequestSendNameAndLogoUploadedFile {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;
  right: 21vw;
}

.RequestSendNameAndLogoUploadedFile img {
  width: 1.5vw;
}