.requestsGrid {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5vh;
}

.requestsGrid table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1vw;
}

.requestsGrid table thead {
  background-color: var(--dark-purple);
  color: var(--white);
  font-family: var(--poppins-light);
  font-size: 1vw;
}

.requestsGrid table tbody tr {
  background-color: var(--light-grey);
  font-family: var(--poppins-regular);
  color: var(--dark-grey3);
}

.requestsGrid table thead th,
.requestsGrid table tbody td {
  padding: 0 1vw;
  height: 7.5vh;
  font-size: 2vh;
}

.requestsGrid table tbody td {
  font-family: var(--poppins-regular);
  font-size: 2vh;
  color: var(--dark-grey4);
  text-align: start;
}

.requestsGrid table thead th:nth-child(1),
.requestsGrid table tbody td:nth-child(1) {
  text-align: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 19vw;
  padding-left: 3vw;
}

.requestsGrid table thead th:nth-child(2),
.requestsGrid table tbody td:nth-child(2) {
  text-align: center;
}

.requestsGrid table thead th:nth-child(4),
.requestsGrid table tbody td:nth-child(4) {
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.requestsGrid table tbody td img {
  width: 2vw;
}

#requestGridSelect {
  background-image: url(../../assets/icons/icon_flecha_gris.svg);
  background-repeat: no-repeat;
  background-size: 70%;
  width: 1.5vw;
  height: 1.5vw;
  appearance: none;
  background-position: center;
  margin-left: .5vw;
}

.requestGridDropBtn {
  width: 1.5vw;
  height: 1.5vw;
  border: 1px solid var(--orange);
  margin-left: .3vw;
}

.requestGridDropBtn img {
  width: 1vw;
}

.requestGridDropdown {
  background-color: var(--white);
  flex-direction: column;
  position: absolute;
  justify-content: center;
  padding: 0 0;
  margin-top: .5vw;
  z-index: 2;
  border-radius: 5px;
}

.isDisabled {
  display: none;
}

.isActive {
  display: flex;
}

.requestGridDropdown button {
  width: 2vw;
  background-color: var(--white);
  border: 0;
  position: absolute;
  right: .2vw;
}

.requestGridDropdown button img {
  width: 1vw;
}

.requestsGridActionsBtn {
  border: none;
}