.editNotAllowedContactRow {
  background-color: var(--light-grey);
  font-family: var(--poppins-regular);
}

.editNotAllowedContactCell {
  padding: 0.5vh 1.5vw;
  width: 10vw;
  font-size: 0.95vw;
  text-align: center;
}

.editNotAllowedContactFile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2vw;
  width: 100%;
}

.editNotAllowedContactPlace {
  padding-left: 3vw;
}

.editNotAllowedContactFileImage {
  width: 25px;
  height: 30px;
}

.editNotAllowedContactSeeBtn {
  width: 2vw;
  border: 0;
  background-color: transparent;
  font-family: var(--poppins-light);
  color: var(--dark-grey4);
  border-radius: 0.7vw;
}

.editNotAllowedContactSeeBtn img{
  width: 100%;
}

.editNotAllowedContactModalBtnBox{
  display: flex;
  justify-content: space-between;
}

.editNotAllowedContactModalBtn {
  color: var(--white);
  background-color: var(--orange);
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
  border: none;
  border-radius: 0.7vw;
  padding: 0.5vw 3vw;
  margin-top: 0.5vw;
  display: flex;
  align-items: center;
}

.editNotAllowedContactModalBtn img{
  width: 1.5vw;
  margin-right: .5vw;
}