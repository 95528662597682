.entriesListContainer,
.newEntitiesListSection,
.newEntitiesListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5vh;
}

#entriesTable {
  width: 98%;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 1vh;
}

#entriesTable th,
#entriesTable td {
  padding: 0 1vw;
  height: 7.5vh;
  font-size: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#entriesTable td {
  font-family: var(--poppins-light);
  font-size: 2vh;
  color: var(--dark-grey4);
  text-align: start;
}

#entriesTableHead {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--dark-purple);
  color: var(--white);
  font-family: var(--poppins-light);
  font-size: 1vw;
  align-items: center;
}

#newEntitiesTableHead {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--dark-purple);
  color: var(--white);
  font-family: var(--poppins-light);
  font-size: 1vw;
  align-items: center;
}

#entriesTableBody {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: var(--poppins-light);
  color: var(--dark-grey3);
  background-color: var(--light-grey);
  margin: 1.5vh 0;
  border-radius: 10px;
  justify-content: space-evenly;
  font-size: 1vw;
  align-items: center;
}

#newEntitiesTableBody {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: var(--poppins-light);
  color: var(--dark-grey3);
  background-color: var(--light-grey);
  margin: 1.5vh 0;
  border-radius: 10px;
  justify-content: flex-start;
  font-size: 1vw;
  align-items: center;
}

#entriesTableHead th:nth-child(1),
#entriesTableBody td:nth-child(1) {
  width: 5%;
}

#newEntitiesTableHead th:nth-child(1),
#newEntitiesTableBody td:nth-child(1) {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#entriesTableHead th:nth-child(2),
#entriesTableBody td:nth-child(2) {
  width: 12%;
}

#newEntitiesTableHead th:nth-child(2),
#newEntitiesTableBody td:nth-child(2) {
  width: 16%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 3vw;
}

#entriesTableHead th:nth-child(3),
#entriesTableBody td:nth-child(3) {
  width: 15%;
}

#newEntitiesTableHead th:nth-child(3),
#newEntitiesTableBody td:nth-child(3) {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 2vw;
}

#entriesTableHead th:nth-child(4),
#entriesTableBody td:nth-child(4) {
  width: 10%;
}

#newEntitiesTableHead th:nth-child(4),
#newEntitiesTableBody td:nth-child(4) {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 3vw;
}

#entriesTableHead th:nth-child(5),
#entriesTableBody td:nth-child(5) {
  width: 22%;
}

#newEntitiesTableHead th:nth-child(5),
#newEntitiesTableBody td:nth-child(5) {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#entriesTableHead th:nth-child(6),
#entriesTableBody td:nth-child(6) {
  width: 5%;
  text-align: center;
}

#newEntitiesTableHead th:nth-child(6),
#newEntitiesTableBody td:nth-child(6) {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#entriesTableHead th:nth-child(7),
#entriesTableBody td:nth-child(7) {
  width: 6%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

#entriesTableBody button {
  border: 0;
}

.editIcon {
  width: 2vw;
}

.carousel-controls {
  width: 20%;
  margin: 0 auto;
  text-align: center;
}

.entriesListHeadCell {
  padding: 2vh 2vw;
  position: relative;
  text-align: left;
  width: 20%;
}

.newEntitiesListHeadCell {
  padding: 2vh 2vw;
  position: relative;
  text-align: left;
  width: 15%;
}

.newEntitiesEditButton {
  border: none;
}