.validacionesAdminSearchContainer {
  display: flex;
  opacity: 0;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 40vw;
  margin: 2vh 0;
}

.validacionesAdminHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-flex-start;
  width: 20vw;
  margin-left: 2vw;
}

.validacionesAdminParagraph {
  color: var(--light-purple);
  font-size: 3vh;
  line-height: 4rem;
  font-family: var(--poppins-bold) !important;
  border-bottom: 5px solid var(--light-purple);
  margin-bottom: -4px;
}

.hrValidacionesAdmin {
  color: var(--light-purple) !important;
  background: var(--light-purple);
  height: 2px !important;
  opacity: 0.5 !important;
  margin: 0.1rem 0 !important;
  margin-bottom: 1vh !important;}

