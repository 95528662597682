.EditPQRSBuyerImg{
    width: 15vw;
    margin-bottom: 3vh
}

.EditPQRSBuyerFormRow{
    display: flex;
    width: 100%;
    gap: 5vw;
}

.EditPQRSBuyerFormField{
    width: 100%;
}

.soloField{
    width: 47%;
}

.doubleField{
    display: flex;
    gap: 1vw;
}

.doubleFieldLeft{
    width: 20%;
}

.doubleFieldRight{
    width: 80%;
}

.EditPQRSBuyerFormKey{
    color: var(--dark-grey);
    font-family: var(--poppins-medium);
    font-size: 1.3vw;
}

.EditPQRSBuyerFormInput{
    box-shadow: inset 6px 8px 25px #00000029;
    padding: 1.7vh 1vw;
    border-radius: 10px;
    margin-right: 1vw;
    border: 0;
    font-family: var(--poppins-light);
    color: var(--dark-grey2);
    font-size: 1.2vw;
    height: 7vh;
    width: 100%;
}