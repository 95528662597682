.lawyersDetailedDescription {
  display: grid;
  grid-template-columns: 50% 48%;
  gap: 2vw;
  margin: 0 1vw;
  min-height: 65vh;
}

.lawyersDetailedDescriptionSubcontainer {
  border-radius: 0.7vw;
  color: var(--dark-grey4);
}

.lawyersDetailedDescriptionDescriptionContainer {
  height: 70%;
}

.lawyersDetailedDescriptionDescriptionInfoContainer{
  background-color: var(--light-grey);
  padding: 1.0vw;
}

.lawyersDetailedDescriptionDescriptionMainTitle{
  color: var(--light-purple);
  font-family: var(--poppins-bold);
  font-size: 1.5vw;
  margin-bottom: 3vh;
}

.lawyersDetailedDescriptionDescriptionTitle,
.lawyersDetailedDescriptionDetailsTitle {
  font-family: var(--poppins-medium);
  font-size: 1.5vw;
  margin: 0;
}

.lawyersDetailedDescriptionDescriptionValue{
  font-family: var(--poppins-regular);
  font-size: 1.5vw;
  margin-bottom: 1vh;
}

.lawyersDetailedDescriptionDescriptionLightValue{
  font-family: var(--poppins-light);
  font-size: 1.5vw;
  margin-bottom: 1vh;
}

.lawyersDetailedDescriptionDescriptionText {
  font-family: var(--poppins-light);
  font-size: 0.9vw;
  max-height: 9vh;
  overflow-y: auto;
}

.lawyersDetailedDescriptionDetailsContainer{
  margin-top: 2vh;
}

.lawyersDetailedDescriptionDetailsHeader {
  display: flex;
  align-items: flex-end;
}

.lawyersDetailedDescriptionDetailsTitle {
  width: 55%;
  color: var(--light-purple);
  font-family: var(--poppins-bold);
  margin-bottom: 1vh;
}

.lawyersDetailedDescriptionState{
  font-family: var(--poppins-regular);
  font-size: 1.2vw;
  margin-top: 1vh;
  margin-left: 1vw;
}

.lawyersDetailedDescriptionState img{
  width: 2vw;
  margin-left: .7vw;
}

.lawyersDetailedDescriptionDetailsUser {
  font-family: var(--poppins-bold);
  color: var(--orange);
  font-size: 1vw;
}

.lawyersDetailedDescriptionDetailsAditionalInfo{
  margin-top: 1vh;
}

.entityName{
  width: 45%;
  color: var(--dark-grey4);
  font-family: var(--poppins-regular);
  font-size: 1.5vw;
  margin-left: .5vw;
  margin-bottom: .5vh;
}