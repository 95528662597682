#EntitiesWithoutChannel{
    padding: 0 .5vw;
}

.EntitiesWithoutChannelTable{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1vw;
}

.EntitiesWithoutChannelTableHead{
    background-color: var(--dark-purple);
    color: var(--white);
    font-family: var(--poppins-light);
    font-size: 1vw;
}

.EntitiesWithoutChannelTableHead tr th:nth-child(1){
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
}

.EntitiesWithoutChannelTableHead tr th:nth-child(5){
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
}

.EntitiesWithoutChannelTableBody{
    background-color: var(--light-grey);
    font-family: var(--poppins-regular);
}

.EntitiesWithoutChannelTableBody td{
    font-size: 1vw;
    color: var(--dark-grey4);
    padding: 0 1vw;
    height: 7.5vh;
}

.EntitiesWithoutChannelTableBody td:nth-child(1){
    text-align: center;
}

.EntitiesWithoutChannelTableBody td:nth-child(4) p{
    width: 15vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.EntitiesWithoutChannelTableBody td:nth-child(5){
    display: flex;
    justify-content: center;
}
.EntitiesWithoutChannelTableBody td:nth-child(5) img{
    width: 2vw;
}