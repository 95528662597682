.AsideBackground {
    background: rgba(119, 36, 86, 0.6);
    backdrop-filter: blur(5px);
    position: fixed;
    z-index: 4;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    display: flex;
    justify-content: flex-end;
    color: white;
  }

.AsideBody{
    background-color: var(--dark-aside-purple);
    width: 25%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5vh 0;
}

.AsideOpen{
    animation: slideLeft .1s;
}

.AsideHidden{
    display: none;
}

.AsideIcon {
    width: 2.5vw;
    margin: 1vh 0;
}

.AsideHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}


.AsideSettled {
    color: var(--white);
    font-family: var(--poppins-light);
    font-size: 1.3vw;
}

.AsideBody-content{
    font-size: 1.5vw;
    width: 85%;
    text-align: start;
}

.AsideBody-content div{
    display: grid;
    grid-template-columns: 8vw 1fr;
    gap: 10px;
}

.AsideBody-content h2{
    font-family: var(--quicksand-bold);
}

.AsideBody-content p{
    font-family: var(--poppins-light);
    font-size: 1.3vw;
    margin-bottom: 2vh;
}

.AsideBody-content span{
    font-family: var(--poppins-light);
    font-size: 1.3vw;
    margin-right: 1vw;
    display: flex;
    align-items: center;
}

.AsideBody-content strong{
    font-family: var(--poppins-bold);
}

.AsideBody-content img{
    width: 3vw;
}

.AsideBody-buttons{
    margin-top: 5vh;
    display: flex;
    justify-content: space-between;
    gap: 5vw;
}

.AsideBody-buttons button{
    font-size: 1.5vw;
    font-family: var(--poppins-medium);
    width: 15vw;
    height: 6vh;
    border-radius: 10px;
}

.Aside-normalBtn{
    background-color: var(--dark-purple);
    color: var(--white);
    border: 1px solid var(--white);
}

.Aside-orangeBtn{
    background-color: var(--orange);
    color: var(--white);
    border: 1px solid var(--white);
}

.Aside-normalBtn:hover{
    color: var(--white);
    background-color: var(--orange);
}

.Aside-greenBtn{
    background-color: var(--green);
    border: none;
    color: var(--white);
    max-width: 7.8vw;
}

@keyframes slideLeft {
    from {
      opacity: 0;
      transform: translateX(2vw);
    }
    to {
      opacity: 1;
    }
  }
