.entradasLayoutContainer {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.entriesHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 95%;
  gap: 20px;
  margin-left: 2vw;
}

.entriesHeaderContainerNavlinks {
  display: flex;
  gap: 1.5vw;
}

.entriesHeaderContainer a {
  text-decoration: none;
  color: var(--light-purple);
  font-size: 1.5vw;
  line-height: 4rem;
  font-family: var(--poppins-medium);
}

.entriesActiveHeader {
  border-bottom: 5px solid var(--light-purple);
}

.entriesActiveHeader:hover {
  color: var(--light-purple);
}

.entriesActiveHeader p {
  font-family: var(--poppins-bold);
}

.entriesHeaderContainerButton {
  border: 0;
  background-color: var(--white);
  position: relative;
}

.entriesHeaderContainerImg {
  width: 4.5vw;
  height: 4.5vw;
}

.entriesHeaderContainerUnreadPqrs {
  width: 1.3vw;
  height: 1.3vw;
  background-color: red;
  position: absolute;
  top: 0.2vw;
  right: 0.5vw;
  font-family: var(--quicksand-bold);
  color: var(--white);
  font-size: 0.6vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.entriesPanelParagraph:hover {
  color: var(--light-purple);
}

.hrEntriesPanel {
  color: var(--light-purple) !important;
  background: var(--light-purple);
  height: 2px !important;
  opacity: 0.5 !important;
  margin: -0.2rem 0 !important;
  margin-bottom: 3vh !important;
}