#CaseTrackingEntityDetail{
    padding: 0 2vw;
}

.CaseTrackingEntityDetailTSide{
    display: flex;
    gap: 1vw;
    margin-bottom: 1.5vh;
}

.CaseTrackingEntityDetailIconBox{
    background-color: var(--light-grey);
    width: 12vw;
    height: 12vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    box-shadow: 0px 3px 6px #00000029;
}

.CaseTrackingEntityDetailIconBox img{
    width: 9vw;
    height: 9vw;
}

.CaseTrackingEntityDetailInfo{
    display: grid;
    gap: 1vw;
    width: 60vw;
    height: 12vw;
    border-radius: 11px;
}

.CaseTrackingEntityDetailInfoLSide, 
.CaseTrackingEntityDetailInfoRSide{
    padding: 2.5vh 1.5vw 2vh 1.5vw;
    background-color: var(--light-grey);
    box-shadow: 0px 3px 6px #00000029;
}

.CaseTrackingEntityDetailTitle{
    color: var(--light-purple);
    font-family: var(--poppins-bold);
    font-size: 1.56vw;
    margin-bottom: 2vh;
}

.CaseTrackingEntityDetailInfoContent{
    display: flex;
}

.CaseTrackingEntityDetailInfoHeader{
    display: flex;
    justify-content: space-between;
}

.CaseTrackingEntityDetailInfoContentLSide{
    max-width: 24vw;
}

.CaseTrackingEntityDetailInfoContentRSide{
    max-width: 24vw;
}

.CaseTrackingEntityDetailInfoContentNoContact{
    gap: 2vh;
    display: flex;
    flex-direction: column;
}

.CaseTrackingEntityDetailInfoContentTSide{
    display: flex;
    gap: 1vw
}

.CaseTrackingEntityDetailInfoContentBSide{
    display: flex;
    gap: 1vw;
}

.titleTextBold{
    font-family: var(--poppins-medium) !important;
}

.CaseTrackingEntityStateText{
    font-family: var(--poppins-medium);
    color: var(--light-purple);
    font-size: 1.2vw;
}

.CaseTrackingEntityStatus{
    font-family: var(--poppins-medium);
    font-size: 1.2vw;
}

.CaseTrackingEntityDetailCurrentStatus{
    display: flex;
    gap: .5vw;
}

.CaseTrackingEntityDetailStateOptions{
    background-color: var(--white);
    padding: 1vh 0;
    width: 100%;
    position: absolute;
    bottom: -9vh;
}

.CaseTrackingEntityDetailStateOptions button{
    padding: 0 1vw;
    font-family: var(--poppins-regular);
    font-size: 1.2vw;
    background-color: transparent;
    border: 0;
    width: 100%;
    text-align: start;
}

.CaseTrackingEntityDetailStateOptions button:hover{
    background-color: var(--orange);
    color: var(--white);
}

.CaseTrackingEntityDetailBtnBox{
    display: flex;
    gap: 1.2vw;
}

.CaseTrackingEntityDetailChangeStatus{
    display: flex;
    gap: .5vw;
    position: relative;
}

.CaseTrackingEntityDetailBtn{
    width: 1.7vw;
    height: 1.7vw;
    padding: .2vh .2vw;
    border: 2px solid var(--orange);
    background-color: var(--white);
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.longWidth{
    width: 56.5vw;
    gap: 5vw;
}

.shortWidth{
    flex-direction: column;
    grid-template-columns: 44% 55%;
}

.minimunLineHeight{
    line-height: 3vh;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.CaseTrackingEntityDetailCompanyName{
    font-family: var(--poppins-medium);
    font-size: 1.25vw;
    color: var(--dark-grey4);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
}

.CaseTrackingEntityDetailText{
    font-family: var(--poppins-regular);
    font-size: 1vw;
    color: var(--dark-grey4);
    width: 100%;
}