@import '/src/App.css';

.unregisteredUserMainContainer {
    flex: 1;
    margin: 0 2vw;
    display: flex;
    flex-direction: column;
    height: 66vh;
    overflow-y: auto;
}

.userRequestHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 24vW;
}

.userRequestHeaderParagraph {
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    font-family: var(--poppins-bold) !important;
    border-bottom: 5px solid var(--light-purple);
    margin-bottom: -4px;
}

.hrUserRequest {
    color: var(--light-purple) !important;
    background: var(--light-purple);
    height: 2px !important;
    opacity: .50 !important;
    margin: 0.1rem 0 6rem 0 !important
}

.dataBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 7px;
}

.userRequestButtonsBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 15vw;
}

.userRequestButtonsBoxBtn {
    width: 10vw;
    height: 5vh;
    font-size: 1.2vw;
    border: 0;
    background-color: var(--orange);
    font-family: var(--poppins-medium);
    color: var(--white);
    justify-content: flex-start;
    margin-bottom: 1vh;
    border-radius: 10px;
}

.isHidden{
    display: none;
}

.userRequestDataContainer {
    width: 100%;
    padding: 1.5vh 4vw;
    background-color: var(--light-grey2);
}

.userRequestSeparatedDataContainer {
    margin: 1.3vh 0;
}

.userRequestSeparatedDataContainerP {
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
    font-size: 1.2vw;
}

.userRequestParagraph {
    font-family: var(--poppins-regular);
    font-size: 1.1vw;
    color: var(--dark-grey4);
}

.userRequestDataSubContainer2 {
    margin-right: 3vw;
    gap: 0 2vh;
}

.userRequestDataSubContainer2 div:nth-child(3){
    height: 7vh;
    display: flex;
    align-items: center;
}

.userRequestDataSubContainer2 img{
    width: 13vw;
}

.userRequestH4 {
    font-family: var(--poppins-bold);
    color: var(--light-purple);
    font-size: 1.3vw;
}

.userRequestInfoContainer {
    display: flex;
    flex-direction: row;
    width: 45vw;
    justify-content: space-between;
}

.petitionRequestComplaintBox {
    background: var(--light-grey2);
    padding: 0.5vw 1vw;
    height: 19vh;
    border-radius: 7px;
    overflow-y: auto;
}

.petitionRequestComplaintTitle {
    font-family: var(--poppins-medium);
    color: var(--light-purple);
    font-size: 1.3vw;
}

.petitionRequestComplaintBoxPID {
    font-family: var(--poppins-medium);
    color: var(--black);
    font-size: 1.3vw;
}

.petitionRequestComplaintBoxP {
    font-family: var(--poppins-regular);
    color: var(--black);
    font-size: 1.2vw;
}

.userRequestFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.userRequestFooterBackLink,
.userRequestFooterBackLink:hover {
    text-decoration: none;
    color: var(--light-purple);
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
}

.userRequestButton,
.userRequestButton:hover {
    background: var(--orange);
    color: var(--white);
    border: none;
    border-radius: 15px;
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
    padding: 1vh 2.5vw;
    text-decoration: none;
}

.userRequestButton:disabled {
    opacity: .3;
}

.userRequestResponse {
    margin-top: 1vh;
}

.userRequestResponse textarea {
    height: 11vh;
}

.userRequestResponse p {
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
    font-size: 1.05vw;
}

.userRequestResponse textarea {
    width: 100%;
    border: 0;
    background-color: var(--light-grey);
    resize: none;
    font-family: var(--poppins-regular);
    font-size: 1.3vw;
    color: var(--dark-grey2);
    padding: 1vh 1vw;
    outline: none;
}

.userRequestRadio {
    font-family: var(--poppins-regular);
    font-size: 1.5vw;
    color: var(--dark-grey4);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5vw;
}

.userRequestRadio input {
    width: 1.5vw;
    height: 1.5vw;
}

::-webkit-scrollbar {
    width: 11px;
}

::-webkit-scrollbar-track {
    background: var(--dark-grey3);
}

::-webkit-scrollbar-thumb {
    background: var(--white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--dark-grey3);
    border-radius: 7px;
}