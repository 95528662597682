#HistoryPays {
  margin: 1vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5vh;
}

#HistoryPays table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1vh;
}

#HistoryPays table thead {
  background-color: var(--dark-purple);
  color: var(--white);
  font-family: var(--poppins-light);
}

#HistoryPays table thead th {
  position: relative;
}

#HistoryPays table tbody {
  background-color: var(--light-grey);
  font-family: var(--poppins-regular);
}

#HistoryPays table thead th,
#HistoryPays table tbody td {
  padding: 0 1vw;
  height: 7.5vh;
  font-size: 2vh;
}

#HistoryPays table tbody td {
  font-family: var(--poppins-regular);
  font-size: 2vh;
  color: var(--dark-grey4);
  text-align: start;
}

#HistoryPays table thead th:nth-child(5),
#HistoryPays table tbody td:nth-child(5) {
  text-align: center;
}

#HistoryPays table tbody td button {
  border: none;
}