@import '/src/App.css';

.newUserHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 20vW;
}

.newUserParagraph {
    color: var(--light-purple);
    font-size: 1.5vw;
    line-height: 4rem;
    font-family: var(--poppins-bold) !important;
    border-bottom: 5px solid var(--light-purple);
}

.hrNewUser {
    color: var(--light-purple) !important;
    background: var(--light-purple);
    height:2px !important;
    opacity: .50 !important;
    margin: 0.1rem 0 !important;
    position: relative;
    bottom: 5px;
}

.newUserAuxContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    line-height: 4vh;
    height: 15vh;
    margin-left: 3vw;
}

.newUserMainTitle {
    font-size: 1.5vw;
    font-family: var(--poppins-medium);
    color: var(--dark-grey4);
}

.newUserMainSubtitle {
    font-size: 1.5vw;
    font-family: var(--poppins-light);
    color: var(--dark-grey4);
}

.newUserCardsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 2vh 0 10vh 0;
}

.newUserSecondaryHR {
    width: 92%;
    margin: auto;
    color: #000000;
    opacity: 0.5 !important;
}

.newUserFooter {
    margin-top: 1vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5vw;
  }

  .newUserFooterBackLink, .newUserFooterBackLink:hover {
    text-decoration: none;
    color: var(--light-purple);
    font-family: var(--poppins-medium);
    font-size: 1.5vw;
  }
  