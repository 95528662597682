.pqrsLayoutContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.headerContainerPQRS {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 20vw;
}

.PQRSAdminContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 20vw;
  margin-left: 2vw;
}

.PQRSAdminParagraph {
  color: var(--light-purple);
  font-size: 3vh;
  line-height: 4rem;
  font-family: var(--poppins-bold) !important;
  border-bottom: 5px solid var(--light-purple);
  margin-bottom: -4px;
}

.hrPQRSAdmin {
  color: var(--light-purple) !important;
  background: var(--light-purple);
  height: 2px !important;
  opacity: 0.5 !important;
  margin: 0.1rem 0 !important;
  margin-bottom: 3vh !important;
}