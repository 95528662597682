.ActiveProcessListTableBox{
    border-radius: 11px;
    display: flex;
    box-shadow: 0px 3px 6px #00000029;
    height: 38vh;
    padding: 5vh 2vw;
    background-color: var(--light-grey);
    overflow-y: auto;
}

.ActiveProcessListTable{
    width: 100%;
    max-height: 100%;
}

.ActiveProcessListTable th:nth-child(3),
.ActiveProcessListTable td:nth-child(3){
    text-align: center;
}

.ActiveProcessListTitle{
    color: var(--light-purple);
    font-family: var(--poppins-semibold);
    font-size: 1.25vw;
}

.ActiveProcessTRow{
    position: relative;
}

.expiredAlertProcessList{
    position: absolute;
}

.expiredAlertProcessListText{
    color: var(--red);
    margin-right: .4vw;
}

.expiredAlertProcessList img{
    width: 1.5vw;
}

.expiredAlertText{
    color: var(--red);
    font-size: 1vw;
}

.ActiveProcessListText{
    padding: .6vh 1vw;
    color: var(--dark-grey4);
    font-family: var(--poppins-regular);
    font-size: 1.25vw;
}

.ActiveProcessListSeeIcon{
    width: 2vw;
}

.ActiveProcessListBtnBox{
    display: flex;
    justify-content: flex-end;
    padding: 2vh 0;
}

.ActiveProcessListButton{
    background-color: var(--orange);
    border: 0;
    border-radius: 10px;
    color: #fff;
    font-family: var(--poppins-medium);
    font-size: 1.56vw;
    padding: 1vh 2vw;
}